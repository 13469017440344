<style>


  @page {
      size:A4 portrait;
      margin-left: 0px;
      margin-right: 0px;
      margin-top: 0px;
      margin-bottom: 0px;
      margin: 0;
      -webkit-print-color-adjust: exact;
      background: rgb(255, 255, 255);
  }

  body
    {
      background: #dae2f0;  /* fallback for old browsers */
      background: -webkit-linear-gradient(to center, #243B55, #c6c6c6);  /* Chrome 10-25, Safari 5.1-6 */
      background: linear-gradient(to center, #243B55, #c6c6c6); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    }
  </body>
  .sortable-chosen {
    opacity: 0.7;
    background-color: #dcdcdc;
  }

  .sortable-ghost {
    background-color: #dcdcdc;
  }

  .flip-list-move {
    transition: transform 0.1s;
  }

  .no-move {
    transition: transform 0s;
  }

  .ghost {
    opacity: 0.85;
    background: #64fa6a;
  }

  .list-group {
    min-height: 20px;
  }

  <style>.image {

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    border: 10px solid #ebebeb;

    margin: 0 auto;


  }

  .link {
    border-top: 35px solid transparent;
    padding: 5px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    display: inline-block;
    padding-top: 78px;
    margin-top: -78px;
  }

  .head_podacha {
    margin: 0 auto;
    margin-top: 15px;
    margin-bottom: 5px;
    padding: 10px;
    border-radius: 10px;
    background: #f0f0f0;
    color: #717171;
    word-wrap: break-word;
    font-size: 1.2rem;
    max-width: 95%;
    min-width: 400px;
    line-height: 2.1rem;
    text-align: justify;
    border: 1px solid rgb(219, 218, 218);
    box-shadow: 2px 2px 1px rgba(142, 139, 139, 0.15);
    z-index: 35;



  }

  .head_podacha_green {
    margin: 0 auto;
    margin-top: 15px;
    margin-bottom: 5px;
    padding: 10px;
    border-radius: 10px;
    background: #dfffd0;
    color: #3ab800;
    font-size: 1.2rem;
    width: 95%;
    line-height: 2.1rem;
    text-align: left;
    border: 1px solid rgb(219, 218, 218);
    box-shadow: 2px 2px 1px rgba(142, 139, 139, 0.15);
    z-index: 35;



  }

  .selected {
    background-color: #f9c7c8;
    border: solid red 1px;
  }

  .flip-list-move {
    transition: transform 0.1s;
  }

  .no-move {
    transition: transform 0s;
  }

  .ghost {
    opacity: 0.85;
    background: #64fa6a;
  }

  .list-group {
    min-height: 20px;

  }

  @keyframes rotation {
    0% {
        transform:rotate(360deg);
    }
    100% {
        transform:rotate(0deg);
    }
}
</style>


<template>

  <v-app id="inspire" style="max-width:800px;margin:0px auto; ">




    <v-app-bar app color="#009d10" dark  style="max-width:800px; margin:0px auto;" >


      <!-- <v-divider class="mx-4" inset vertical></v-divider>
    <div class="white--text text-center shadow" style="line-height:1.15rem;font-size:1.25rem; margin-top:5px"> Документ - онлайн <br /><span style="font-size: 0.69rem;  opacity:0.9; color: #ffffff">ООО «Бюро тарифных расчетов»</span></div>
    <v-divider class="mx-4" inset vertical></v-divider>
    -->


      <v-toolbar-title >


          <table cellspacing="0" cellpadding="0" style="font-size:1.1rem; font-face:Arial; text-align:left;   width:100%; ">
            <tr>
                <td style="font-size:clamp(6pt, 4.1vw,14pt);" >




                  <span style="font-size:  22px; text-shadow: 1px 1px 2px rgba(97, 7, 145, 0.69)">Календарь</span>



                  <a style="text-decoration:none" href="/"><v-icon large class="mr-0 "  style="color:rgb(255, 255, 255); text-shadow: 1px 1px 7px rgb(115, 246, 69); ">mdi-flash</v-icon></a>
                    <span style="font-size:  22px; text-shadow: 1px 1px 2px rgba(97, 7, 145, 0.69)">Тарифы и отчеты</span>

                  </td>

            </tr>

          </table>


      </v-toolbar-title>

    </v-app-bar>





    <v-main>
      <v-tabs

            background-color="success"
            color="#009d10"
            active-class="success darken-2 white--text white--background"
            v-model="tab1"

            fixed-tabs
            show-arrows
            :style="showwidth()>500?'max-height:35px':''"



          >
            <v-tab
             class="caption black--text"
             :style="showwidth()>500?'max-height:35px':''"


            >
              Календарь
            </v-tab>
            <v-tab
class="caption black--text"
:style="showwidth()>500?'max-height:35px':''"

            >
              Гос.Данные
            </v-tab>
            <v-tab
class="caption black--text"
:style="showwidth()>500?'max-height:35px':''"

            >
              <a @click="tab1=1" class="caption black--text" target="_blanc" style="text-decoration:none" href="https://261.рф">261.рф</a>
            </v-tab>
            <v-tab
class="caption black--text"
:style="showwidth()>500?'max-height:35px':''"

            >
                <a @click="tab1=1" class="caption black--text" target="_blanc" style="text-decoration:none" href="https://46.btr-online.ru">46тэ.свод</a>
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab1">
            <v-tab-item

            >




      <v-divider class=" mt-0" />
      <p class="body-2 pr-1 pl-1 mb-2 mt-1 text-center" style="line-height:0.95rem; color:grey "  >
    Календарь документов и отчётности для регулируемых организаций теплоснабжения, водоснабжения и электросетевых компаний. Составлено с учётом законодательства в Самарской области.




    </p>

<v-divider class=" mb-1" />

      <table style="" class="ml-5 ">
  <tbody>
    <tr>


      <td class="text-bold body-3">

Настройки календаря:



        <span class="body-2" style="font-size:14px; ">
          <v-btn @click="settings=true"  label   v-if="te" x-small color="grey lighten-2" elevation="0" class="outlined mb-1 mr-1" style="text-transform: none; font-face:Arial; font-size:9pt;font-weight:400;letter-spacing: normal;">
            <span v-if="tereg">Теплоснабжение</span>
            <span v-if="tenereg">Теплоснабжение (нерег. или ЦЗ)</span>

          </v-btn>
          <v-btn @click="settings=true"  label   v-if="tn" x-small color="grey lighten-2" elevation="0" class="outlined mb-1 mr-1" style="text-transform: none; font-face:Arial; font-size:9pt;font-weight:400;letter-spacing: normal;">

            <span v-if="tereg">Теплоноситель</span>
            <span v-if="tenereg">Теплоноситель (нерег. или ЦЗ)</span>
          </v-btn>
          <v-btn  @click="settings=true" label   v-if="gvs" x-small color="grey lighten-2" elevation="0" class="outlined mb-1 mr-1" style="text-transform: none; font-face:Arial; font-size:9pt;font-weight:400;letter-spacing: normal;">
            ГВС
          </v-btn>
          <v-btn  @click="settings=true"  label   v-if="vs" x-small color="grey lighten-2" elevation="0" class="outlined mb-1 mr-1" style="text-transform: none; font-face:Arial; font-size:9pt;font-weight:400;letter-spacing: normal;">
            Водоснабжение
          </v-btn>
          <v-btn  @click="settings=true" label  v-if="vo" x-small color="grey lighten-2" elevation="0" class="outlined mb-1 mr-1" style="text-transform: none; font-face:Arial; font-size:9pt;font-weight:400;letter-spacing: normal;">
            Водоотведение
          </v-btn>
          <v-btn  @click="settings=true" label  v-if="trte" x-small color="grey lighten-2" elevation="0" class="outlined mb-1 mr-1" style="text-transform: none; font-face:Arial; font-size:9pt;font-weight:400;letter-spacing: normal;">
            Транспортировка тепловой энергии
          </v-btn>
          <v-btn  @click="settings=true" label  v-if="trvs" x-small color="grey lighten-2" elevation="0" class="outlined mb-1 mr-1" style="text-transform: none; font-face:Arial; font-size:9pt;font-weight:400;letter-spacing: normal;">
            Транспортировка воды
          </v-btn>
          <v-btn  @click="settings=true" label  v-if="trvo" x-small color="grey lighten-2" elevation="0" class="outlined mb-1 mr-1" style="text-transform: none; font-face:Arial; font-size:9pt;font-weight:400;letter-spacing: normal;">
            Транспортировка стоков
          </v-btn>
          <v-btn  @click="settings=true" label  v-if="ee" x-small color="grey lighten-2" elevation="0" class="outlined mb-1 mr-1" style="text-transform: none; font-face:Arial; font-size:9pt;font-weight:400;letter-spacing: normal;">
            Передача электроэнергии
          </v-btn>
          <v-btn  @click="settings=true" label  v-if="osno" x-small color="grey lighten-2" elevation="0" class="outlined mb-1 mr-1" style="text-transform: none; font-face:Arial; font-size:9pt;font-weight:400;letter-spacing: normal;">
            ОСНО
          </v-btn>
          <v-btn  @click="settings=true" label  v-if="usno" x-small color="grey lighten-2" elevation="0" class="outlined mb-1 mr-1" style="text-transform: none; font-face:Arial; font-size:9pt;font-weight:400;letter-spacing: normal;">
            УСНО
          </v-btn>
          <v-btn  @click="settings=true" label  v-if="budg" x-small color="grey lighten-2" elevation="0" class="outlined mb-1 mr-1" style="text-transform: none; font-face:Arial; font-size:9pt;font-weight:400;letter-spacing: normal;">
            Бюджетный учет
          </v-btn>
          <v-btn  @click="settings=true" label  v-if="mp" x-small color="grey lighten-2" elevation="0" class="outlined mb-1 mr-1" style="text-transform: none; font-face:Arial; font-size:9pt;font-weight:400;letter-spacing: normal;">
            Малое предприятие
          </v-btn>
          <v-btn  @click="settings=true" label  v-if="p4" x-small color="grey lighten-2" elevation="0" class="outlined mb-1 mr-1" style="text-transform: none; font-face:Arial; font-size:9pt;font-weight:400;letter-spacing: normal;">
            Сдаётся П4
          </v-btn>
          <v-btn  onclick="settings=true" label  v-if="nep4" x-small color="grey lighten-2" elevation="0" class="outlined mb-1 mr-1" style="text-transform: none; font-face:Arial; font-size:9pt;font-weight:400;letter-spacing: normal;">
            Не сдаётся П4
          </v-btn>
          <v-btn  @click="settings=true"   v-if="ot15" x-small color="grey lighten-2" elevation="0" class="outlined mb-1 mr-1" style="text-transform: none; font-face:Arial; font-size:9pt;font-weight:400;letter-spacing: normal;">
            Более 15 сотр.
          </v-btn>

          </span>
          <span style="text-align:left; "><v-btn x-small   class=" white--text mr-2 ml-2  mt-2 mb-2  " color="rgba(0, 157, 16, 0.8)"  @click="settings=true" style=" ;margin-top:-5px"><v-icon >mdi-toggle-switch</v-icon>настроить</v-btn></span>




      </td>


    </tr>
  </tbody>

</table>

<v-divider/>


<div class="text-center mt-2 mb-0">




  <span  style="font-size:  35px; font-weight:600 ; color:#6c6c6c ;  ">Календарь</span>
  </div>
  <div class="text-right" v-if="showwidth()>500">
    <v-btn small rounded text  class="  mr-5 mt-0  " color="rgb(144, 144, 144)"  @click="printvisible=true; printoutcalendar();" style="margin-top:-5px"><v-icon >mdi-printer</v-icon>Распечатать</v-btn>
  </div>

<v-divider class="mt-2"/>
<v-expansion-panels multiple focusable >

  <v-expansion-panel v-for="(item,i) in months" :key="i">
    <v-expansion-panel-header class="text-align-left">

      {{item}}<span class="ml-2">
        <v-chip small dense>{{calendar.filter(item=>(((i>0?item['MON'+i]:item.specdata) && reportfilter(item)))).length}}</v-chip>
      </span>



    </v-expansion-panel-header>

    <v-expansion-panel-content>
      <table cellspacing="7" style="" class="mt-5">


          <tbody>
            <tr v-for="(item2,j) in calendar.filter((item3=>(i>0?item3['MON'+i]:item3.specdata) && reportfilter(item3))).sort(( a, b ) => a['MON'+i] > b['MON'+i] ? 1 : -1 )" :key="j" >
              <td v-if="i>0" style=" width:60px; border-radius:10px;background: rgb(238, 238, 238);border-bottom:3px solid rgb(219, 219, 219);font-size:  11pt ; " class="text-center  "> {{ item2['MON'+i] }}<br />{{monthshort[i]}} </td>

              <td  style="box-shadow: 2px 2px 5px rgba(142, 139, 139, 0.45); border-radius:10px;">
                <table class="text-caption" width="100%" style="page-break-inside:avoid;border:1px solid rgb(219, 219, 219); border-radius:10px; ">

                  <tr v-if="i>0">
                    <td class="pl-2 pr-2 text-caption " style="  background:rgb(247, 247, 247);padding:2px; line-height:0.95rem; text-align:justify"><b> {{item2['name'] }}</b></td>

                  </tr>
                  <tr v-if="i===0">
                    <td class="pl-2 pt-2 pb-2 pr-2 pr-2  " style="  background:rgb(247, 247, 247); line-height:0.95rem;text-align:justify"> <b> {{item2['name'] }}</b></td>

                  </tr>

                  <tr v-if="i===0">
                    <td class="pl-2" style="line-height:0.95rem; "> <b>Сроки: </b>{{ item2.specdata }} </td>
                  </tr>
                  <tr>
                    <td class="pl-2" style="line-height:0.95rem;"> <b>Сбор:</b> {{ i>0?item2['NAM'+i]:item2.specnazv}}</td>

                  </tr>
                  <tr>


                    <td class="pl-2" style="line-height:0.95rem;"><b>Форма:</b> {{item2.form}}</td>
                  </tr>
                  <tr v-if="item2.comment">


                    <td class="pl-2" style=" line-height:0.95rem;"><b>Комментарий:</b> {{item2.comment}}</td>
                  </tr>


                  <tr>


                    <td class="pl-2 pb-1" style="background: rgb(247, 247, 247)"><a :href="item2.link">{{item2.osn}}</a> </td>

                  </tr>

                </table>
              </td>



            </tr>
          </tbody>

      </table>

    </v-expansion-panel-content>
  </v-expansion-panel>

  <v-expansion-panel disabled>
    <v-expansion-panel-header class="text-align-left">

      Всего отобрано отчётов и документов <span class="ml-2">
        <v-chip small dense> {{ calcreports(calendar.filter(item333=>(reportfilter(item333))))}}</v-chip>
      </span>



    </v-expansion-panel-header>

  </v-expansion-panel>
</v-expansion-panels>



<v-dialog v-model="printvisible"   fullscreen >

  <v-card >

    <v-card-text>
<div id="calendarprint" >
<div style="padding:50px; font-family: 'Arial'; font-size:10pt; background: rgb(255, 255, 255);">


<div style="text-align: center; margin-bottom:5px; margin-top:5px;width:100%; padding-top:400px; page-break-after: always">


  <span style="font-size:  22px; font-weight:bold;   ">Календарь</span>



  <a style="text-decoration:none" href="/"><v-icon  medium  style="color:rgb(0, 0, 0); margin-bottom:-15px; height:35px; width:35px;">mdi-flash</v-icon></a>
    <span style="font-size:  22px; font-weight:bold; ">Тарифы и отчеты</span>


<div class="body-2" style="font-size:14px;  margin-top:10px; text-align:center ">
  <div style="background:rgb(213, 213, 213); padding:3px; padding-right:4px; color:black; border-radius:5px; margin:3px;display: inline-block;"  label   v-if="te" small class="outlined ml-1 mb-1 mr-1">
    <div v-if="tereg">Теплоснабжение</div>
    <div v-if="tenereg">Теплоснабжение (нерег. или ЦЗ)</div>

  </div>
  <div style="background:rgb(213, 213, 213);;; padding:3px; padding-right:4px; color:black; border-radius:5px; margin:3px;  display: inline-block;  ;"   label   v-if="tn" small class="outlined mb-1 mr-1">

    <div v-if="tereg">Теплоноситель</div>
    <div v-if="tenereg" >Теплоноситель (нерег. или ЦЗ)</div>
  </div>
  <div style="background:rgb(213, 213, 213); padding:3px; padding-right:4px; color:black; border-radius:5px; margin:3px; display: inline-block;"     label   v-if="gvs" small class="outlined mb-1 mr-1">
    ГВС
  </div>
  <div style="background:rgb(213, 213, 213); padding:3px; padding-right:4px; color:black; border-radius:5px; margin:3px; display: inline-block;"  label   v-if="vs" small class="outlined mb-1 mr-1">
    Водоснабжение
  </div>
  <div style="background:rgb(213, 213, 213); padding:3px; padding-right:4px; color:black; border-radius:5px; margin:3px; display: inline-block;"   label  v-if="vo" small class="outlined mb-1 mr-1">
    Водоотведение
  </div>
  <div style="background:rgb(213, 213, 213); padding:3px; padding-right:4px; color:black; border-radius:5px; margin:3px; display: inline-block; "   label  v-if="trte" small class="outlined mb-1 mr-1">
    Транспортировка тепловой энергии
  </div>
  <div style="background:rgb(213, 213, 213); padding:3px; padding-right:4px; color:black; border-radius:5px; margin:3px; display: inline-block;"   label  v-if="trvs" small class="outlined mb-1 mr-1">
    Транспортировка воды
  </div>
  <div style="background:rgb(213, 213, 213); padding:3px; padding-right:4px; color:black; border-radius:5px; margin:3px; display: inline-block;"   label  v-if="trvo" small class="outlined mb-1 mr-1">
    Транспортировка стоков
  </div>
  <div style="background:rgb(213, 213, 213); padding:3px; padding-right:4px; color:black; border-radius:5px; margin:3px; display: inline-block;"   label  v-if="ee" small class="outlined mb-1 mr-1">
    Передача электроэнергии
  </div>
  <div style="background:rgb(213, 213, 213); padding:3px; padding-right:4px; color:black; border-radius:5px; margin:3px; display: inline-block;"  label  v-if="osno" small class="outlined mb-1 mr-1">
    ОСНО
  </div>
  <div style="background:rgb(213, 213, 213); padding:3px; padding-right:4px; color:black; border-radius:5px; margin:3px; display: inline-block;"   label  v-if="usno" small class="outlined mb-1 mr-1">
    УСНО
  </div>
  <div style="background:rgb(213, 213, 213); padding:3px; padding-right:4px; color:black; border-radius:5px; margin:3px; display: inline-block;"   label  v-if="budg" small class="outlined mb-1 mr-1">
    Бюджетный учет
  </div>
  <div style="background:rgb(213, 213, 213); padding:3px; padding-right:4px; color:black; border-radius:5px; margin:3px; display: inline-block;"   label  v-if="mp" small class="outlined mb-1 mr-1">
    Малое предприятие
  </div>
  <div style="background:rgb(213, 213, 213); padding:3px; padding-right:4px; color:black; border-radius:5px; margin:3px; display: inline-block;"   label  v-if="p4" small class="outlined mb-1 mr-1">
    Сдаётся П4
  </div>
  <div style="background:rgb(213, 213, 213); padding:3px; padding-right:4px; color:black; border-radius:5px; margin:3px; display: inline-block;"   label  v-if="nep4" small class="outlined mb-1 mr-1">
    Не сдаётся П4
  </div>
  <div style="background:rgb(213, 213, 213); padding:3px; padding-right:4px; color:black; border-radius:5px; margin:3px; display: inline-block;"   label  v-if="ot15" small class="outlined mb-1 mr-1">
    Более 15 сотр.
  </div>

  </div>
  </div>

<div v-for="(item,i) in months" :key="i" >
  <div  style="font-size:  20px;  page-break-inside:avoid; font-weight:bold; margin-top:15px; padding:5px; margin-bottom:10px; text-align:center ">

    {{item}}<span >
      <span style="background:black; padding:5px; padding-right:7px; color:white; border-radius:10px; margin-left:3px" >{{calendar.filter(item=>(((i>0?item['MON'+i]:item.specdata) && reportfilter(item)))).length}}</span>
    </span>



  </div>


    <table cellspacing="7" style="margin-top:5px" >


        <tbody>
          <tr v-for="(item2,j) in calendar.filter((item3=>(i>0?item3['MON'+i]:item3.specdata) && reportfilter(item3))).sort(( a, b ) => a['MON'+i] > b['MON'+i] ? 1 : -1 )" :key="j" >
            <td v-if="i>0" style=" width:60px; border-radius:10px;background: rgb(238, 238, 238);border:3px solid rgb(219, 219, 219);font-size:  11pt ; text-align:center " >
              {{ item2['MON'+i] }}<br />{{monthshort[i]}} </td>

            <td  style="box-shadow: 2px 2px 5px rgba(142, 139, 139, 0.45); border-radius:10px;">
              <table width="100%" style="page-break-inside:avoid;border:1px solid rgb(219, 219, 219); border-radius:10px; ">

                <tr v-if="i>0">
                  <td  style="padding:7px;  background:rgb(247, 247, 247);line-height:0.95rem; text-align:justify"><b> {{j+1}}. {{item2['name'] }}</b></td>

                </tr>
                <tr v-if="i===0">
                  <td  style="padding:7px;  background:rgb(247, 247, 247); line-height:0.95rem;text-align:justify"> <b>{{j+1}}. {{item2['name'] }}</b></td>

                </tr>

                <tr v-if="i===0">
                  <td  style="padding:7px;;line-height:0.95rem; "> <b>Сроки: </b>{{ item2.specdata }} </td>
                </tr>
                <tr>
                  <td style="padding:7px;line-height:0.95rem;"> <b>Сбор:</b> {{ i>0?item2['NAM'+i]:item2.specnazv}}</td>

                </tr>
                <tr>


                  <td  style="padding:7px;line-height:0.95rem;"><b>Форма:</b> {{item2.form}}</td>
                </tr>
                <tr v-if="item2.comment">


                  <td  style="padding:7px;;line-height:0.95rem;"><b>Комментарий:</b> {{item2.comment}}</td>
                </tr>


                <tr>


                  <td  style="padding-left:2px;padding-bottom:2px;background: rgb(247, 247, 247);padding:7px;"><a :href="item2.link">{{item2.osn}}</a> </td>

                </tr>

              </table>
            </td>



          </tr>
        </tbody>

    </table>


</div>

<div>
  <div style="font-size:  20px; font-weight:bold; margin-top:15px; margin-bottom:10px; text-align:center ">

    Всего отобрано отчётов и документов <span style="background:black; padding:5px; padding-right:7px; color:white; border-radius:10px; margin-left:3px" >
    {{ calcreports(calendar.filter(item333=>(reportfilter(item333))))}}
    </span>



  </div>
  <p class="body-2" align="center" style="color:black;border-top:1px solid #009d10; page-break-inside:avoid;  width:100%; margin:0px auto; padding:10px;padding-top:15px; line-height:1.2rem ">
    ООО «Бюро тарифных расчетов»
<br>


  e-mail: <a style="color:black;" href="mailto:info@btr-online.ru">info@btr-online.ru</a>
<br>
<a style="color:black;" href="tel:+78462216058">8 (846) 221-60-58</a>
<br>
     <a style="color:black;" href="http://btr-online.ru">btr-online.ru</a>
  </p>

</div>
</div>
</div>
</v-card-text>
</v-card >


</v-dialog>


</v-tab-item>

<v-tab-item>
  <v-divider class=" mt-0" />
  <p class="body-2 pr-1 pl-1 mb-2 mt-1 text-center" style="line-height:0.95rem; color:grey "  >
Просмотр сведений об организации из систем: Росстат, ЕГРЮЛ, Государственный информационный ресурс бухгалтерской отчётности, Единый реестр субъектов малого и среднего предпринимательства, официальное опубликование приказов по тарифам на pravo.samregion.ru, раскрытие информации на сайте RI.EIAS.RU.
</p>
<v-divider class=" mt-2" />

<p class="body-2 pr-1 pl-1 ml-5 mt-5 text-left text-bold"   >
  <table>

    <tr>
      <td>
               <v-combobox
          v-model="inn"
          :items="firms"
          label="Введите ИНН или часть названия"
          style="width:320px; margin-bottom:-20px;"
          item-text="ORG"
          item-value="INN"
          outlined
          @keydown.enter="validateInn(inn)"
          @change="validateInn(inn)"
          :return-object="false"

          dense
        ></v-combobox>
{{err.message}}
             </td>

             </tr>
             <tr>
      <td >

          <v-btn small rounded  class="mt-0 white--text" color="#009d10"  @click="validateInn(inn,err)" ><v-icon >mdi-magnify</v-icon>Поиск в ГИС</v-btn>
      </td>
    </tr>
    <tr>
      <td class="pt-2" v-if="false">
      <v-chip v-if="fnsprogress" label class="mb-2 ml-1 "><v-progress-circular indeterminate :size="20" :width="2" color="white" class="mr-1" ></v-progress-circular>ФНС</v-chip>
      <v-chip v-if="rosstatprogress" label class="mb-2 ml-1 "><v-progress-circular indeterminate :size="20" :width="2" color="white" class="mr-1" ></v-progress-circular>РОССТАТ</v-chip>
      <v-chip v-if="egrulprogress" label class="mb-2 ml-1 "><v-progress-circular indeterminate :size="20" :width="2" color="white" class="mr-1" ></v-progress-circular>ЕГРЮЛ</v-chip>
      <v-chip v-if="ermspprogress" label class="mb-2 ml-1 "><v-progress-circular indeterminate :size="20" :width="2" color="white" class="mr-1" ></v-progress-circular>ЕРМСП</v-chip>
      <v-chip v-if="prikaziprogress" label class="mb-2 ml-1 "><v-progress-circular indeterminate :size="20" :width="2" color="white" class="mr-1" ></v-progress-circular>Официальное опубликование</v-chip>
      <v-chip v-if="RIprogress" label class="mb-2 ml-1 "><v-progress-circular indeterminate :size="20" :width="2" color="white" class="mr-1" ></v-progress-circular>Раскрытие информации</v-chip>
    </td>
    </tr>
  </table>
  </p>


  <p v-if="'l' in egruldata" align="center" style="margin:5px;"> <b>   {{egruldata['rows'][0].n}}</b></p>
  <p v-else align="center" style="margin:5px;"> <b style="color:white"> _ </b></p>

  <v-tabs

        background-color="rgb(204, 204, 204)"
        color="#000000"
        active-class="grey darken-1 white--text white--background rounded"
        v-model="tab3"
        v-if="(RI.length>0 || rizero)+('l' in egruldata)+((!girbozero) & (fnsdata.length>0))+('data' in mspdata)+(rosstatdata.length>0)+(prikazidata.length>0)+(prikazieedata.length>0)"

        fixed-tabs

        show-arrows
        :style="showwidth()>500?'max-height:35px; width:95%; margin:20px; border-radius:5px ':''"



      >

      <v-tab
      class="caption black--text"
      :style="showwidth()>500?'max-height:35px':''"

      >
        Раскрытие Информации

          <v-icon small color="rgb(255, 255, 255)"
          v-if="RIprogress"
          style="animation-name: rotation;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    box-shadow:rgb(246, 255, 254) 1p 1px 1px;

    animation-timing-function: linear;">      mdi-sync     </v-icon>
        </v-tab>

      <v-tab
class="caption black--text"
:style="showwidth()>500?'max-height:35px':''"

      >
          Тарифы
          <v-icon small color="rgb(255, 255, 255)"
          v-if="prikaziprogress"
          style="animation-name: rotation;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    box-shadow:rgb(246, 255, 254) 1p 1px 1px;

    animation-timing-function: linear;">      mdi-sync     </v-icon>
      </v-tab>

        <v-tab
         class="caption black--text"
         :style="showwidth()>500?'max-height:35px':''"


        >
          ЕГРЮЛ
          <v-icon small color="rgb(255, 255, 255)"
          v-if="egrulprogress"
          style="animation-name: rotation;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    box-shadow:rgb(246, 255, 254) 1p 1px 1px;

    animation-timing-function: linear;">      mdi-sync     </v-icon>
        </v-tab>
        <v-tab
class="caption black--text"
:style="showwidth()>500?'max-height:35px':''"

        >
          ГИРБО
          <v-icon small color="rgb(255, 255, 255)"
          v-if="fnsprogress"
          style="animation-name: rotation;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    box-shadow:rgb(246, 255, 254) 1p 1px 1px;

    animation-timing-function: linear;">      mdi-sync     </v-icon>
        </v-tab>
        <v-tab
class="caption black--text"
:style="showwidth()>500?'max-height:35px':''"

        >
          ЕРСМСП
          <v-icon small color="rgb(255, 255, 255)"
          v-if="ermspprogress"
          style="animation-name: rotation;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    box-shadow:rgb(246, 255, 254) 1p 1px 1px;

    animation-timing-function: linear;">      mdi-sync     </v-icon>
        </v-tab>
        <v-tab
class="caption black--text"
:style="showwidth()>500?'max-height:35px':''"

        >
            Росстат
            <v-icon small color="rgb(255, 255, 255)"
            v-if="rosstatprogress"
            style="animation-name: rotation;
      animation-duration: 1s;
      animation-iteration-count: infinite;
      box-shadow:rgb(246, 255, 254) 1p 1px 1px;

      animation-timing-function: linear;">      mdi-sync     </v-icon>
        </v-tab>

      </v-tabs>

  <div :style="(showwidth()<500)?'':'padding-left:30px;padding-right:30px; padding-bottom:30px;'">

    <v-tabs-items v-model="tab3">
      <v-tab-item>



               <p v-if="RI.length>0 || rizero" style="color:#464646; font-weight:bold;" class="body-3   text-center   ml-5 mt-5">Маркеры раскрытия информации по отчетным периодам - <a target="_blank" style="text-decoration:none; color:#464646; " href="https://ri.eias.ru">ri.eias.ru<v-icon small>mdi-open-in-new</v-icon></a></p>

               <v-expansion-panels v-if="RI.length>0" style="margin-top:-10px"   >

                 <v-expansion-panel>
                   <v-expansion-panel-header class="text-align-left black--text" color="rgb(233, 232, 232)">

                    Найдено блоков данных: {{RI.length}}. Просмотреть...



                   </v-expansion-panel-header>

                   <v-expansion-panel-content>

                     <div style="max-height:800px; overflow-y:scroll; margin-right:-20px; margin-left:-20px; ">


                         <table v-for="item in RI" :key="item" class="text-caption mt-2 ml-2"   style="float:left;page-break-inside:avoid;border:1px solid rgb(219, 219, 219); border-radius:10px;line-height:0.8rem ; margin:  0px; ;min-width:220px; min-height:295px;">

                           <tr >
                             <td class="pl-3 pr-2 pb-2 pt-2" colspan="2" style="border-bottom:1px  dotted rgb(203, 201, 201);  background:rgb(235, 233, 233); border-radius:10px; height:25px;"> {{ri_caption[item['sphere']]}} за <b> {{item['year']}} год </b></td>

                           </tr>
                           <tr >
                             <td class="pl-3 pr-2 pb-2 pt-2" style="border-bottom:1px  dotted rgb(203, 201, 201);  "> TERMS</td><td style="border-bottom:1px  dotted rgb(203, 201, 201); "> <v-icon   @click="ridialog=true; rireq(item['sphere'],item['year'],inn,'TERMS' );"  :color="item['terms']>0?'green':'grey'">mdi-square-rounded</v-icon> </td>

                           </tr>
                           <tr v-if="item['sphere']=='Теплоснабжение'">
                             <td class="pl-3 pr-2 pb-2 pt-2" style="border-bottom:1px  dotted rgb(203, 201, 201);  "> LIMIT</td><td style="border-bottom:1px  dotted rgb(203, 201, 201); ">
                                <v-icon @click="ridialog=true; rireq(item['sphere'],item['year'],inn,'QUARTER (LIMIT)' );" :color="item['l1']>0?'green':'grey'">mdi-numeric-1-box</v-icon>
                                <v-icon @click="ridialog=true; rireq(item['sphere'],item['year'],inn,'QUARTER (LIMIT)' );" :color="item['l2']>0?'green':'grey'">mdi-numeric-2-box</v-icon>
                                <v-icon  @click="ridialog=true; rireq(item['sphere'],item['year'],inn,'QUARTER (LIMIT)' );" :color="item['l3']>0?'green':'grey'">mdi-numeric-3-box</v-icon>
                                <v-icon @click="ridialog=true; rireq(item['sphere'],item['year'],inn,'QUARTER (LIMIT)' );" :color="item['l4']>0?'green':'grey'">mdi-numeric-4-box</v-icon>

                              </td>

                           </tr>
                           <tr v-if="item['sphere']!=='ТКО'">
                             <td class="pl-3 pr-2 pb-2 pt-2" style="border-bottom:1px  dotted rgb(203, 201, 201);  "> QUARTER</td><td style="border-bottom:1px  dotted rgb(203, 201, 201);" >
                                <v-icon @click="ridialog=true; rireq(item['sphere'],item['year'],inn,'QUARTER' );" :color="item['q1']>0?'green':'grey'">mdi-numeric-1-box</v-icon>
                                <v-icon @click="ridialog=true; rireq(item['sphere'],item['year'],inn,'QUARTER' );"  :color="item['q2']>0?'green':'grey'">mdi-numeric-2-box</v-icon>
                                <v-icon @click="ridialog=true; rireq(item['sphere'],item['year'],inn,'QUARTER' );"  :color="item['q3']>0?'green':'grey'">mdi-numeric-3-box</v-icon>
                                <v-icon @click="ridialog=true; rireq(item['sphere'],item['year'],inn,'QUARTER' );"  :color="item['q4']>0?'green':'grey'">mdi-numeric-4-box</v-icon>

                              </td>

                           </tr>
                           <tr >
                             <td class="pl-3 pr-2 pb-2 pt-2" style="border-bottom:1px  dotted rgb(203, 201, 201);  "> BALANCE</td><td style="border-bottom:1px  dotted rgb(203, 201, 201);" > <v-icon @click="ridialog=true; rireq(item['sphere'],item['year'],inn,'BALANCE' );"  :color="item['balance']>0?'green':'grey'">mdi-square-rounded</v-icon> </td>

                           </tr>
                           <tr >
                             <td class="pl-3 pr-2 pb-2 pt-2" style="border-bottom:1px  dotted rgb(203, 201, 201);  "> REQUEST</td><td style="border-bottom:1px  dotted rgb(203, 201, 201); "> <v-icon @click="ridialog=true; rireq(item['sphere'],item['year'],inn,'REQUEST' );"   :color="item['request']>0?'green':'grey'">mdi-square-rounded</v-icon> </td>

                           </tr>
                           <tr >
                             <td class="pl-3 pr-2 pb-2 pt-2" style="border-bottom:1px  dotted rgb(203, 201, 201);  "> PRICE</td><td style="border-bottom:1px  dotted rgb(203, 201, 201);" > <v-icon  @click="ridialog=true; rireq(item['sphere'],item['year'],inn,'PRICE' );"  :color="item['price']>0?'green':'grey'">mdi-square-rounded</v-icon> </td>

                           </tr>
                           <tr >
                             <td class="pl-3 pr-2 pb-2 pt-2" style="border-bottom:1px  dotted rgb(203, 201, 201);  "> INVEST</td><td style="border-bottom:1px  dotted rgb(203, 201, 201);" > <v-icon  @click="ridialog=true; rireq(item['sphere'],item['year'],inn,'INVEST' );"   :color="item['invest']>0?'green':'grey'">mdi-square-rounded</v-icon> </td>

                           </tr>
                           <tr v-if="new Date().getFullYear()==item['year']" >
                             <td class="pl-3 pr-2 pb-2 pt-2" style="border-bottom:1px  dotted rgb(203, 201, 201);  "> ORG</td><td style="border-bottom:1px  dotted rgb(203, 201, 201);" > <v-icon  @click="ridialog=true; rireq(item['sphere'],item['year'],inn,'ORG' );"   :color="item['org']>0?'green':'grey'">mdi-square-rounded</v-icon> </td>

                           </tr>






                         </table>

</div>

               </v-expansion-panel-content>
               </v-expansion-panel>
                 </v-expansion-panels>

                 <p v-if="RI.length>0 || rizero" style="color:#464646; font-weight:bold;" class="body-3   text-center   ml-5 mt-5"> Аудит сроков сдачи по отдельному году - <a target="_blank" style="text-decoration:none; color:#464646; " href="https://ri.eias.ru">ri.eias.ru<v-icon small>mdi-open-in-new</v-icon></a></p>





                 <v-expansion-panels v-if="RI.length>0" style="margin-top:-10px"   >

                   <v-expansion-panel>
                     <v-expansion-panel-header class="text-align-left black--text" color="rgb(233, 232, 232)">

                    Просмотреть материалы аудита...



                     </v-expansion-panel-header>

                     <v-expansion-panel-content>

                         <br/>


                        <v-expansion-panels v-if="RI.length>0" style="margin-top:-10px"   >

                          <v-expansion-panel>

                            <v-expansion-panel-header class="text-align-left black--text" color="rgb(233, 232, 232)" >
                                       Настройки отображения видов деятельности <v-spacer></v-spacer><v-spacer></v-spacer><v-spacer></v-spacer>






                            </v-expansion-panel-header>

                            <v-expansion-panel-content>

                       <v-card style="margin-top:12px;border:1px solid rgb(170, 170, 170);" >

                         <v-card-text >



                           <table width="100%">
                             <tr>
                               <td style="vertical-align:top; ">

                                             <p class="text-bold text-center text-overline purple--text" style="margin-top:-15px" >виды деятельности
                                               <br/>


                                 <v-btn   outlined  small :color="te?'deep-purple accent-4':'grey'" @click="te=!te" v-model="te"    style="height:45px;max-width:45px; padding:-5px ;border-radius:8px; margin:3px;">

                                   <span class="caption">ТЭ</span>
                                 </v-btn>
                                 <v-btn   outlined  small :color="gvs?'deep-purple accent-4':'grey'" @click="gvs=!gvs"  v-model="gvs"   style="height:45px;max-width:45px; padding:-5px;border-radius:8px; margin:3px; ">

                                   <span class="caption">ГВС</span>
                                 </v-btn>



                                 <v-btn   outlined  small :color="vs?'deep-purple accent-4':'grey'" @click="vs=!vs"  v-model="vs"   style="height:45px;max-width:45px; padding:-5px;border-radius:8px; margin:3px; ">

                                   <span class="caption">ВС</span>
                                 </v-btn>


                                 <v-btn   outlined  small :color="vo?'deep-purple accent-4':'grey'" @click="vo=!vo"  v-model="vo"   style="height:45px;max-width:45px; padding:-5px;border-radius:8px; margin:3px;">

                                   <span class="caption">ВО</span>
                                 </v-btn>



                                           </p>



                               </td>
                             </tr>
                           </table>







           <v-divider  v-if="te||tn" style="margin-top:-10px;"/>

                           <table v-if="te||tn" cellpadding=0 cellspacing=0 width="100%" style="max-width:800px;">


                           <tr >
                             <td style="border-bottom:1px solid rgb(233, 233, 233)" class="  text-right">Нерегулируемое тепло (в т.ч. ценовые зоны)</td>
                             <td  style="border-bottom:1px solid rgb(233, 233, 233)">
                               <v-switch :false-value="false" :true-value="true"
                               color="deep-purple accent-3"
                               class="pl-2"
                             style=""
                             :value="tereg"
                             v-model="tereg"
                               @click="tenereg=!tenereg;  "
                         ></v-switch></td>
                             <td style="border-bottom:1px solid rgb(233, 233, 233)"  class=" text-left"> Рег. тарифы на ТЭ</td>
                           </tr>


                           </table>





                         </v-card-text>


                       </v-card>


                     </v-expansion-panel-content>
                     </v-expansion-panel>
                   </v-expansion-panels>









                       <div class="">

                         <template>


                           <div style="border-radius:10px; padding-bottom:7px;margin: 10px 0px 10px 0px;border:1px solid grey">


                           <p :key="deya"  style="padding:5px; padding-left:15px;margin-top:7px;font-weight:600; margin-bottom:7px;" >
                            Выберите вид деятельности для отображения:
                           </p>

                           <v-chip-group
        v-model="tab55"
        active-class="deep-purple--text text--accent-4"
        mandatory
        show-arrows
        style="margin-top:-10px; margin-left:10px;"

      >


        <v-chip

        style="text-align:left;font-size:8pt;"

          outlined


         v-for="(deya) in vd.filter(((d,indexvd)=>((this[d]===true) && d!=='ee' && indexvd<8)))"
         :key="deya"


        >
          {{vdtext[vd.indexOf(deya)]}}
        </v-chip>
      </v-chip-group>




                             <v-tabs-items v-model="tab55">
                               <v-tab-item
                               v-for="(deya) in vd.filter(((d,indexvd)=>((this[d]===true) && d!=='ee' && indexvd<8)))"
                               :key="deya"


                               >



                               <p :key="deya" style="padding:5px; margin-top:7px;font-weight:600; background:rgb(236, 236, 236)" >

                                 <table style="width:100%">

                                   <tr>
                                     <td style="width:50px"> <v-btn icon @click="tab55--"><v-icon>mdi-arrow-left</v-icon></v-btn></td>
                                     <td style="width:95%">{{vdtext[vd.indexOf(deya)]}}</td>
                                     <td style="width:50px"><v-btn icon @click="tab55++"><v-icon>mdi-arrow-right</v-icon></v-btn></td>
                                   </tr>
                                 </table>

                              </p>
                              <p  class="purple--text text-bold text-center text-overline" style="margin-top:-15px; margin-bottom:-12px;" >Период проверки (год)

                                   <br/>

                               <v-btn @click="god_rabot_filter=Number(god_rabot_filter)-1;"
                                 solo    small style="margin-top:-10px ; margin-right: 5px;height:45px; background:rgb(238, 213, 246)" ><v-icon >mdi-minus</v-icon></v-btn>
                                          <v-text-field  solo style="color: rgb(131, 2, 157);font-size:1.7rem; width:85px; display:inline-block; margin-right:4px; "   v-model="god_rabot_filter"  label="Год работ" default="String(new Date().getFullYear()).slice(-2)"  dense></v-text-field>

                                          <v-btn @click="god_rabot_filter=Number(god_rabot_filter)+1;"   solo    small style="margin-top:-10px ; margin-right: 5px;height:45px; background:rgb(238, 213, 246)" ><v-icon >mdi-plus</v-icon></v-btn>

                                          </p>


                                <table   dense  :key="deya"   style="font-size:8pt;border:1px solid rgb(170, 170, 170); margin-top:-9px; line-height:0.7rem "   >
                                  <thead style="height:30px; background:rgb(221, 221, 221);">
                                    <th width="35%" style="font-size:8pt; border:1px solid rgb(242, 242, 242)">Отчет, период, срок сдачи</th>


                                    <th style="font-size:8pt; border:1px solid rgb(242, 242, 242)">Фактические даты сдачи отчётов<v-icon style="margin-top:-8px; margin-left:-1px;" color="red" x-small>mdi-record</v-icon></th>

                                  </thead>
                                  <tbody style=" line-height:0.7rem"  >

                                  <tr v-for="el in calendar.filter((item3=>(reportfilter(item3) && item3[deya]+0===1 && (item3.form.search('OPEN.INFO')>-1)))).sort(( a, b ) => a['MON'+i] > b['MON'+i] ? 1 : -1 )" :key="el" >
                                    <td style="font-size:8pt; border:1px solid rgb(242, 242, 242) ;line-height:0.7rem"><b>{{el['form'].split('.')[3]}}</b>,
                                      <table  dense >
                                        <tr v-for="jj in Array(1,2,3,4,5,6,7,8,9,10,11,12).filter(dd=>(el['MON'+dd]))" :key="jj">
                                          <td :style=" Array(1,2,3,4,5,6,7,8,9,10,11,12).filter(dd=>(el['MON'+dd])).length>1?'line-height:0.7rem':'line-height:0.7rem'">

                                            {{el['NAM'+jj]+' '}}
                                            {{el['form'].split('.')[3]=="QUARTER"?
                                             el['NAM'+jj]=="4 кв."?(god_rabot_filter-1)+' года':god_rabot_filter+' года':''}}
                                           - {{el['MON'+jj]}}/{{padTo2Digits(jj)}}/{{god_rabot_filter}}</td>

                                        </tr>

                                        <tr v-if="el.specdata" :key="jj">
                                          <td :style=" Array(1,2,3,4,5,6,7,8,9,10,11,12).filter(dd=>(el['MON'+dd])).length>1?'line-height:0.7rem':'line-height:0.7rem'">{{el.specnazv}} - {{el.specdata}}</td>

                                        </tr>
                                      </table>
                                    </td>



                                    <td style="font-size:8pt;border:1px solid rgb(242, 242, 242)">
                                      <tr>
                                        <td><b style="color:rgb(133, 10, 199); padding:3px;">
                                          {{el['form'].split('.')[3]=='TERMS'?'На '+god_rabot_filter+' год:':''}}
                                          {{el['form'].split('.')[3]=='BALANCE'?'За '+(god_rabot_filter-1)+' год:':''}}
                                          </b>
                                        </td>
                                      </tr>






                                      <tr v-for="jj in Array(1,2,3,4,5,6,7,8,9,10,11,12).filter(dd=>(el['MON'+dd]))" :key="jj">
                                        <td :style=" Array(1,2,3,4,5,6,7,8,9,10,11,12).filter(dd=>(el['MON'+dd])).length>1?'line-height:0.7rem':'line-height:0.7rem'">


                                          <table dense v-if="el['form'].split('.')[3]=='TERMS'">
                                            <tr v-for="stroka in RItablo.filter(tbl=>(tbl.year==god_rabot_filter && tbl.forma=='TERMS' && !tbl.data_doc && tbl.sphere==ri_keys[vdtext[vd.indexOf(deya)]]))" :key="stroka">

                                              <td style="line-height:0.7rem">

                                                <v-icon  :color="(new Date(new Date(stroka.data_publ).toDateString())) <= new Date(god_rabot_filter+'-'+padTo2Digits(jj)+'-'+ el['MON'+jj]) ?'green':'orange' ">mdi-square-rounded</v-icon>
      </td><td style="padding-bottom:3px;padding-top:5px;padding-left:3px">
                                              {{formatDate(stroka.data_publ)}} ({{stroka.MR}})</td>


                                            </tr>



                                            <tr v-if="RItablo.filter(tbl=>(tbl.year==god_rabot_filter && tbl.forma=='TERMS' && !tbl.data_doc && tbl.sphere==ri_keys[vdtext[vd.indexOf(deya)]])).length==0" >

                                              <td style="line-height:0.7rem"  >


                                                  <v-icon  color="rgb(231, 96, 164)">mdi-square-rounded</v-icon>

                                              </td><td style="padding-bottom:3px;padding-top:5px; padding-left:3px; line-height:0.7rem ">
                                                В данном периоде отчеты не сдавались</td>


                                            </tr>

                                          </table>

                                          <table dense v-if="el['form'].split('.')[3]=='BALANCE'">
                                            <tr v-for="stroka in RItablo.filter(tbl=>(tbl.year==(god_rabot_filter-1) && tbl.forma=='BALANCE' && tbl.sphere==ri_keys[vdtext[vd.indexOf(deya)]]))" :key="stroka">

                                              <td style="line-height:0.7rem">

                                                <v-icon  :color="(new Date(new Date(stroka.data_publ).toDateString())) <= new Date(god_rabot_filter+'-'+padTo2Digits(jj)+'-'+ el['MON'+jj]) ?'green':'orange' ">mdi-square-rounded</v-icon>
                                              </td><td style="padding-bottom:3px;padding-top:5px;padding-left:3px;line-height:0.7rem">
                                              {{formatDate(stroka.data_publ)}} ({{stroka.MR}})</td>


                                            </tr>

                                            <tr v-if="RItablo.filter(tbl=>(tbl.year==(god_rabot_filter-1) && tbl.forma=='BALANCE' && tbl.sphere==ri_keys[vdtext[vd.indexOf(deya)]])).length==0" >

                                              <td style="width:20px;line-height:0.7rem"  >

                                                <v-icon  color="rgb(231, 96, 164)">mdi-square-rounded</v-icon>

                                            </td><td style="text-align:left;padding-bottom:3px;padding-top:5px; padding-left:3px; line-height:0.7rem ">
                                              В данном периоде отчеты не сдавались</td>


                                            </tr>

                                          </table>

                                          <table style="width:100%;border:1px dotted rgb(14, 167, 0);border-radius:10px; box-shadow: #b7f78f 1px 0px 2px;margin-left:2px;margin-right:1px" v-if="el['form'].split('.')[3]=='QUARTER' && el['NAM'+jj]=='4 кв.'">
                                            <tr>
                                              <td colspan="2" style="padding-left:3px;"><b style=" padding-left:3px;color:rgb(133, 10, 199); ">  {{el['NAM'+jj]+' '+(god_rabot_filter-1)+' года: '}}</b></td>
                                            </tr>
                                            <tr v-for="stroka in RItablo.filter(tbl=>(
                                              tbl.year==(god_rabot_filter-1) &&
                                              (tbl.period=='4 кв. '+(god_rabot_filter-1)+' года') &&
                                              ((tbl.forma=='QUARTER') || (tbl.forma=='QUARTER (LIMIT)')) &&
                                              (tbl.sphere==ri_keys[vdtext[vd.indexOf(deya)]])
                                              ))" :key="stroka">

                                              <td style="line-height:0.7rem" >

                                                <v-icon  :color="(new Date(new Date(stroka.data_publ).toDateString())) <= new Date(god_rabot_filter+'-'+padTo2Digits(jj)+'-'+ el['MON'+jj]) ?'green':'orange' ">mdi-square-rounded</v-icon>

                                              </td><td style="padding-bottom:3px;padding-top:5px;padding-left:3px;line-height:0.7rem; ">

                                            <b>  {{'4 кв. '+(god_rabot_filter-1)+' года ('+stroka.forma+'): '}}</b> {{formatDate(stroka.data_publ)}} ({{stroka.MR}})</td>


                                            </tr>

                                            <tr v-if=" RItablo.filter(tbl=>(
                                              tbl.year==(god_rabot_filter-1) &&
                                              (tbl.period=='4 кв. '+(god_rabot_filter-1)+' года') &&
                                              ((tbl.forma=='QUARTER') || (tbl.forma=='QUARTER (LIMIT)')) &&
                                              (tbl.sphere==ri_keys[vdtext[vd.indexOf(deya)]])
                                              )).length==0" >

                                              <td  style="width:20px;line-height:0.7rem" >

                                                <v-icon  color="rgb(231, 96, 164)">mdi-square-rounded</v-icon>

                                            </td><td style="text-align:left;padding-bottom:3px;padding-top:5px; padding-left:3px; line-height:0.7rem ">
                                              В данном периоде отчеты не сдавались</td>


                                            </tr>

                                          </table>

                                          <table style="width:100%;border:1px dotted rgb(14, 167, 0);border-radius:10px;; box-shadow: #b7f78f 1px 0px 2px;margin-left:2px;margin-right:1px" v-if="el['form'].split('.')[3]=='QUARTER' && el['NAM'+jj]!='4 кв.'">
                                              <tr>
                                                <td colspan="2" style="padding-left:3px; "><b style=" color:rgb(133, 10, 199); padding:3px; ">  {{el['NAM'+jj]+' '+(god_rabot_filter)+' года: '}}</b></td>
                                              </tr>
                                            <tr v-for="stroka in RItablo.filter(tbl=>(
                                              tbl.year==(god_rabot_filter) &&
                                              (tbl.period==el['NAM'+jj]+' '+(god_rabot_filter)+' года') &&
                                              ((tbl.forma=='QUARTER') || (tbl.forma=='QUARTER (LIMIT)')) &&
                                              (tbl.sphere==ri_keys[vdtext[vd.indexOf(deya)]])
                                              ))" :key="stroka">

                                              <td style="line-height:0.7rem" >
                                                <v-icon  :color="(new Date(new Date(stroka.data_publ).toDateString())) <= new Date(god_rabot_filter+'-'+padTo2Digits(jj)+'-'+ el['MON'+jj]) ?'green':'orange' ">mdi-square-rounded</v-icon>

                                              </td><td style="padding-bottom:3px;padding-top:5px;padding-left:3px;line-height:0.7rem">

                                            <b>  {{el['NAM'+jj]+' '+(god_rabot_filter)+' года ('+stroka.forma+'): '}}</b> {{formatDate(stroka.data_publ)}} ({{stroka.MR}})</td>


                                            </tr>

                                            <tr v-if=" RItablo.filter(tbl=>(
                                              tbl.year==(god_rabot_filter) &&
                                              (tbl.period==el['NAM'+jj]+' '+(god_rabot_filter)+' года') &&
                                              ((tbl.forma=='QUARTER') || (tbl.forma=='QUARTER (LIMIT)')) &&
                                              (tbl.sphere==ri_keys[vdtext[vd.indexOf(deya)]])
                                              )).length==0" >

                                              <td style="width:20px;line-height:0.7rem"  >

                                                <v-icon  color="rgb(231, 96, 164)">mdi-square-rounded</v-icon>

                                            </td><td style="padding-bottom:3px;padding-top:5px; padding-left:3px; line-height:0.7rem ">
                                              В данном периоде отчеты не сдавались</td>


                                            </tr>

                                          </table>


                                        </td>



                                      </tr>

                                      <tr v-if="el.specdata" :key="jj">


                                        <table dense v-if="el['form'].split('.')[3]=='INVEST'">
                                          <tr v-for="stroka in RItablo.filter(tbl=>(new Date(tbl.data_publ).getFullYear()==(god_rabot_filter) && tbl.forma=='INVEST' && tbl.sphere==ri_keys[vdtext[vd.indexOf(deya)]]))" :key="stroka">


                                            <td  style="line-height:0.7rem" >


                                              <v-icon  color="grey">mdi-square-rounded</v-icon>

                                              </td><td style="padding-bottom:3px;padding-top:5px;padding-left:3px">
                                              {{formatDate(stroka.data_publ)}}</td>


                                          </tr>
                                          <tr v-if="RItablo.filter(tbl=>(new Date(tbl.data_publ).getFullYear()==(god_rabot_filter) && tbl.forma=='INVEST' && tbl.sphere==ri_keys[vdtext[vd.indexOf(deya)]])).length==0" >

                                            <td style="line-height:0.7rem"  >

                                              <v-icon  color="rgb(231, 96, 164)">mdi-square-rounded</v-icon>

                                          </td><td style="padding-bottom:3px;padding-top:5px; padding-left:3px; line-height:0.7rem ">
                                            В данном периоде отчеты не сдавались</td>


                                          </tr>

                                        </table>

                                        <table dense  v-if="el['form'].split('.')[3]=='REQUEST'">
                                          <tr  v-for="stroka in RItablo.filter(tbl=>(new Date(tbl.data_publ).getFullYear()==(god_rabot_filter) && tbl.forma=='REQUEST' && tbl.sphere==ri_keys[vdtext[vd.indexOf(deya)]]))" :key="stroka">

                                            <td style="line-height:0.7rem">


                                              <v-icon  :color="(new Date(new Date(stroka.data_publ).toDateString())).getTime() <= (30*24*60*60000 + new Date(stroka.data_doc).getTime()) ?'green':'orange' ">mdi-square-rounded</v-icon>

      </td><td style="padding-bottom:3px;padding-top:5px;padding-left:3px;; line-height:0.7rem ">
                                              {{formatDate(stroka.data_publ)}},         <span style="white-space: nowrap;;color:rgb(123, 71, 150);margin-right:3px; "> <v-icon style="color:rgb(123, 71, 150);margin-right:3px; margin-top:-1px;" small>mdi-comment-text-outline</v-icon>

       №{{stroka.nomer_doc}} от {{formatDate(stroka.data_doc)}}</span>  ({{stroka.MR}})</td>


                                          </tr>

                                          <tr v-if="RItablo.filter(tbl=>(new Date(tbl.data_publ).getFullYear()==(god_rabot_filter) && tbl.forma=='REQUEST' && tbl.sphere==ri_keys[vdtext[vd.indexOf(deya)]])).length==0" >

                                            <td  style="line-height:0.7rem" >

                                              <v-icon  color="rgb(231, 96, 164)">mdi-square-rounded</v-icon>

                                          </td><td style="padding-bottom:3px;padding-top:5px; padding-left:3px; line-height:0.7rem ">
                                            В данном периоде отчеты не сдавались</td>


                                          </tr>

                                        </table>


                                        <table dense v-if="el['form'].split('.')[3]=='PRICE'">
                                          <tr v-for="stroka in RItablo.filter(tbl=>(new Date(tbl.data_publ).getFullYear()==(god_rabot_filter) && tbl.forma=='PRICE' && tbl.sphere==ri_keys[vdtext[vd.indexOf(deya)]]))" :key="stroka">

                                            <td >

                                              <v-icon  :color="(new Date(new Date(stroka.data_publ).toDateString())).getTime() <= (30*24*60*60000 + new Date(stroka.data_doc).getTime()) ?'green':'orange' ">mdi-square-rounded</v-icon>

                                            </td><td style="padding-bottom:3px;padding-top:5px;padding-left:3px;; line-height:0.7rem ">
                                              {{formatDate(stroka.data_publ)}},         <span style="white-space: nowrap;;color:rgb(123, 71, 150);margin-right:3px; "> <v-icon style="color:rgb(123, 71, 150);margin-right:3px; margin-top:-1px;" small>mdi-comment-text-outline</v-icon>

       №{{stroka.nomer_doc}} от {{formatDate(stroka.data_doc)}}</span>  ({{stroka.MR}})</td>


                                          </tr>

                                          <tr v-if="RItablo.filter(tbl=>(new Date(tbl.data_publ).getFullYear()==(god_rabot_filter) && tbl.forma=='PRICE' && tbl.sphere==ri_keys[vdtext[vd.indexOf(deya)]])).length==0" >

                                            <td  >

                                              <v-icon  color="rgb(231, 96, 164)">mdi-square-rounded</v-icon>

                                          </td><td style="padding-bottom:3px;padding-top:5px; padding-left:3px; line-height:0.7rem ">
                                            В данном периоде отчеты не сдавались</td>


                                          </tr>

                                        </table>

                                        <table dense v-if="el['form'].split('.')[3]=='ORG'">
                                          <tr v-for="stroka in RItablo.filter(tbl=>(new Date(tbl.data_publ).getFullYear()==(god_rabot_filter) && tbl.forma=='ORG' && tbl.sphere==ri_keys[vdtext[vd.indexOf(deya)]]))" :key="stroka">

                                            <td  >

                                              <v-icon  color="grey">mdi-square-rounded</v-icon>

                                            </td><td style="padding-bottom:3px;padding-top:5px;padding-left:3px;; line-height:0.7rem ">
                                              {{formatDate(stroka.data_publ)}}</td>


                                          </tr>

                                          <tr v-if="RItablo.filter(tbl=>(new Date(tbl.data_publ).getFullYear()==(god_rabot_filter) && tbl.forma=='ORG' && tbl.sphere==ri_keys[vdtext[vd.indexOf(deya)]])).length==0" >

                                            <td  >

                                              <v-icon  color="rgb(231, 96, 164)">mdi-square-rounded</v-icon>

                                          </td><td style="padding-bottom:3px;padding-top:5px; padding-left:3px; line-height:0.7rem ">
                                            В данном периоде отчеты не сдавались</td>


                                          </tr>


                                        </table>

                                      </tr>





                                    </td>


                                   </tr>
                                    <tr>

                                   <td style="vertical-align:top; padding:10px;" colspan="2">

                                     <p  class="text-bold text-center text-overline " style="width:100%;line-height:0.8rem; "><v-icon color="red" style="margin-top:-3px;" x-small>mdi-record</v-icon>Обозначения

                                     </p>
                                       <v-icon color="green">mdi-square-rounded</v-icon> - сдано в срок <br>
                                       <v-icon color="orange">mdi-square-rounded</v-icon> - корректировка (в том числе, доп. материалы) или сдано позже срока. В дополнение к срокам, указанным в таблице, следует учитывать нормы статьи 193 ГК РФ о переносе сроков сдачи отчётов на ближайший рабочий день, если последний день приёма отчёта выпал на выходной день.<br>
                                       <v-icon color="rgb(231, 96, 164)">mdi-square-rounded</v-icon> - не сдано<br>
                                       <v-icon color="grey">mdi-square-rounded</v-icon> - сдано (без контроля срока)




                                   </td>
                                 </tr>

                                   </tbody>
                                   </table>

                               </v-tab-item

                               >
                             </v-tabs-items>
                             </div>

                           </template>



                       </div>

<br/>






                 </v-expansion-panel-content>
                 </v-expansion-panel>
                   </v-expansion-panels>


                 <p v-if="RI.length>0 || rizero" style="color:#464646; font-weight:bold;" class="body-3   text-center   ml-5 mt-5">Журнал сданных отчётов в ЕИАС - <a target="_blank" style="text-decoration:none; color:#464646; " href="https://ri.eias.ru">ri.eias.ru<v-icon small>mdi-open-in-new</v-icon></a></p>

                 <v-expansion-panels v-if="RI.length>0" style="margin-top:-10px"   >

                   <v-expansion-panel>
                     <v-expansion-panel-header class="text-align-left black--text" color="rgb(233, 232, 232)">

                      Найдено записей об отчётах: {{RItablo.length}}. Просмотреть...



                     </v-expansion-panel-header>

                     <v-expansion-panel-content >

<br/>
<div style="max-height:500px; overflow-y:scroll;  margin-right:-20px; margin-left:-20px; padding-right:10px;">
  <v-simple-table dense  style="font-size:8pt;border:1px solid rgb(170, 170, 170);  "   >
    <thead style="height:30px; background:rgb(221, 221, 221);">
      <th width="90" style="font-size:8pt; border:1px solid rgb(242, 242, 242)">Дата сдачи</th>
      <th  style="font-size:8pt; border:1px solid rgb(242, 242, 242)">Форма, период, сфера, документ</th>

      <th style="font-size:8pt; border:1px solid rgb(242, 242, 242)">Муниципалитет, организация</th>


    </thead>
    <tbody  >
    <tr v-for="el in RItablo" :key="el" >




      <td style="font-size:8pt; border:1px solid rgb(242, 242, 242);line-height:0.7rem">{{formatDateTime(el['data_publ'])}}</td>
      <td style="font-size:8pt;border:1px solid rgb(242, 242, 242);line-height:0.7rem"><b>{{el['forma']}}</b> <span v-if="el['period']!=='Единоразовый'">за </span><span style="white-space: nowrap; text-transform: lowercase;">{{el['period']}}</span>: <span style="white-space: nowrap;">{{el['sphere']}}</span>
         <br v-if="(el['forma']=='REQUEST') || (el['forma']=='PRICE')">

        <b v-if="(el['forma']=='REQUEST') || (el['forma']=='PRICE')" style="font-size:7pt;color:rgb(123, 71, 150); padding:3px; "> <v-icon style="color:rgb(123, 71, 150);margin-right:3px; margin-top:-1px;" small>mdi-comment-text-outline</v-icon>{{(el['forma']=="REQUEST") || (el['forma']=="PRICE")?'№'+el['nomer_doc']:''}}
           {{(el['forma']=="REQUEST") || (el['forma']=="PRICE")?' от '+formatDate(el['data_doc']):''}}</b></td>

      <td style="font-size:8pt;border:1px solid rgb(242, 242, 242);line-height:0.7rem">{{el['MR']}}<span v-if="el['MR']">,</span> <span style="white-space: nowrap;">{{el['ORG']}}</span></td>


    </tr>
  </tbody>
  </v-simple-table>
  <br>
</div>





                 </v-expansion-panel-content>
                 </v-expansion-panel>
                   </v-expansion-panels>

<br>
<v-icon small color="rgb(61, 61, 61)"
v-if="UPDprogress"
style="animation-name: rotation;
animation-duration: 1s;
animation-iteration-count: infinite;
box-shadow:rgb(246, 255, 254) 1p 1px 1px;

animation-timing-function: linear;">      mdi-sync     </v-icon>
                   <v-btn v-if="rstcod!=''" outlined  @click="updateeias()">
                    <v-icon>mdi-sync</v-icon> Обновить с портала RI.EIAS.RU
                   </v-btn>

                 <table v-if="rizero" cellspacing="7" style=" margin:0px auto;margin-top:-20px;" :width="showwidth()>500?'100%':'90%'" >


                     <tbody>
                       <tr >



                   <td  style="box-shadow: 2px 2px 5px rgba(142, 139, 139, 0.45); border-radius:10px;">
                     <p class="ml-3 mr-3 mb-3 mt-3">Поиск по порталу RI.EIAS.RU произведён.
                     <b>Сведений по раскрытию информации не найдено.</b></p>
                   </td>



                 </tr>
               </tbody>

               </table>


      </v-tab-item>

      <v-tab-item>


           <p v-if="prikazidata.length>0" style="color:#464646; font-weight:bold;" class="body-3   text-center   ml-5 mt-5">Приказы о регулировании деятельности организации, источник - <a target="_blank" style="text-decoration:none; color:#464646;" href="https://pravo.samregion.ru">pravo.samregion.ru<v-icon small>mdi-open-in-new</v-icon></a></p>

           <v-expansion-panels v-if="prikazidata.length>0" style="margin-top:-10px"   >

             <v-expansion-panel>
               <v-expansion-panel-header class="text-align-left black--text" color="rgb(233, 232, 232)">

                Найдено приказов: {{prikazidata.length}}. Просмотреть...



               </v-expansion-panel-header>

               <v-expansion-panel-content>

           <table v-if="prikazidata.length>0" cellspacing="7" style="margin-top:5px;"   >


               <tbody>
                 <tr v-for="item in prikazidata" :key="item" >


                   <td  style="box-shadow: 2px 2px 5px rgba(142, 139, 139, 0.45); border-radius:10px;">
                     <table class="text-caption"   style="page-break-inside:avoid;border:1px solid rgb(219, 219, 219); border-radius:10px;line-height:0.8rem ; margin:  0px;">

                       <tr>
                         <td class="pl-3 pr-2 pb-2 pt-2" style="border-bottom:1px  dotted rgb(203, 201, 201);  background:rgb(235, 233, 233); border-radius:10px;"> <b> Номер опубликования {{item['nomer_publ']}} от {{item['data_publ'][8]}}{{item['data_publ'][9]}}.{{item['data_publ'][5]}}{{item['data_publ'][6]}}.{{item['data_publ'][0]}}{{item['data_publ'][1]}}{{item['data_publ'][2]}}{{item['data_publ'][3]}} г.  (запись от {{item['time_add']}}) </b></td>

                       </tr>
                       <tr >
                         <td class="pl-2 pt-1 pb-1 " style="border-bottom:1px  dotted rgb(203, 201, 201);"> {{item['nazv']}}</td>

                       </tr>
                       <tr >
                         <td class="pl-2 pt-1 pb-1 " style="border-bottom:1px  dotted rgb(203, 201, 201);">
                           <div style="word-wrap: break-word;  overflow:hidden; max-width:80%">
                           <b> Ссылка на публикацию:</b> <a target="_blank"  :href="item.link_publ">{{item.link_publ}}</a>
                         </div></td>


                       <tr v-for="item3 in JSON.parse(item.links)" :key="item3"  >
                         <td class="pl-2"  > <div class="pl-2 pt-2 pb-2 " style="word-wrap: break-word;  overflow:hidden;max-width:80%">

                         <b> {{item3.nazv}}:</b> <a  target="_blank" :href="item3.link">{{item3.link}}</a> </div> </td>
                       </tr>






                     </table>
                   </td>



                 </tr>
               </tbody>

           </table>

           </v-expansion-panel-content>
           </v-expansion-panel>
             </v-expansion-panels>

             <p v-if="prikazieedata.length>0" style="color:#464646; font-weight:bold;" class="body-3   text-center   ml-5 mt-5">Приказы о регулировании электросетевого комплекса, источник - <a target="_blank" style="text-decoration:none; color:#464646; " href="https://pravo.samregion.ru">pravo.samregion.ru<v-icon small>mdi-open-in-new</v-icon></a></p>

             <v-expansion-panels v-if="prikazieedata.length>0" style="margin-top:-10px"   >

               <v-expansion-panel>
                 <v-expansion-panel-header class="text-align-left black--text" color="rgb(233, 232, 232)">

                  Найдено приказов: {{prikazieedata.length}}. Просмотреть...



                 </v-expansion-panel-header>

                 <v-expansion-panel-content>

             <table v-if="prikazieedata.length>0" cellspacing="7" style="margin-top:5px;"   >


                 <tbody>
                   <tr v-for="item in prikazieedata" :key="item" >


                     <td  style="box-shadow: 2px 2px 5px rgba(142, 139, 139, 0.45); border-radius:10px;">
                       <table class="text-caption"   style="page-break-inside:avoid;border:1px solid rgb(219, 219, 219); border-radius:10px;line-height:0.8rem ; margin:  0px;">

                         <tr >
                           <td class="pl-3 pr-2 pb-2 pt-2" style="border-bottom:1px  dotted rgb(203, 201, 201);  background:rgb(235, 233, 233); border-radius:10px;"> <b> Номер опубликования {{item['nomer_publ']}} от {{item['data_publ'][8]}}{{item['data_publ'][9]}}.{{item['data_publ'][5]}}{{item['data_publ'][6]}}.{{item['data_publ'][0]}}{{item['data_publ'][1]}}{{item['data_publ'][2]}}{{item['data_publ'][3]}} г.  (запись от {{item['time_add']}}) </b></td>

                         </tr>
                         <tr >
                           <td class="pl-2 pt-1 pb-1 " style="border-bottom:1px  dotted rgb(203, 201, 201); ;padding:2px"> {{item['nazv']}}</td>

                         </tr>
                         <tr >
                           <td class="pl-2 pt-1 pb-1 " style="border-bottom:1px  dotted rgb(203, 201, 201);">
                             <div style="word-wrap: break-word;  overflow:hidden">
                             <b> Ссылка на публикацию:</b> <a target="_blank"  :href="item.link_publ">{{item.link_publ}}</a>
                           </div></td>


                         <tr v-for="item3 in JSON.parse(item.links)" :key="item3"  >
                           <td class="pl-2 pb-2 pt-2"  > <div style="word-wrap: break-word;  padding-bottom:4px; max-width:80% ">

                           <b> {{item3.nazv}}:</b> <a  target="_blank" :href="item3.link">{{item3.link}}</a> </div> </td>
                         </tr>






                       </table>
                     </td>



                   </tr>
                 </tbody>

             </table>

             </v-expansion-panel-content>
             </v-expansion-panel>
               </v-expansion-panels>

      </v-tab-item>

      <v-tab-item>

          <p v-if="'l' in egruldata" style="color:#464646; font-weight:bold;" class="body-3   text-center   ml-5 mt-5">Сведения из ЕГРЮЛ, включая выписку<a style="text-decoration:none;text-shadow:none;  " target="_blank" href="https://egrul.nalog.ru"><v-icon small>mdi-open-in-new</v-icon></a></p>

          <table v-if="'l' in egruldata" cellspacing="7" style=" margin:0px auto;margin-top:-10px;" :width="showwidth()>500?'100%':'90%'" >


              <tbody>
                <tr >


                  <td  style="box-shadow: 2px 2px 5px rgba(142, 139, 139, 0.45); border-radius:10px;">
                    <table class="text-caption"  width="100%" style="page-break-inside:avoid;border:1px solid rgb(219, 219, 219); border-radius:10px;line-height:0.8rem ; margin:  0px;">

                      <tr >
                        <td class="pl-3 pr-2 pb-2 pt-2" style="border-bottom:1px  dotted rgb(203, 201, 201);  background:rgb(235, 233, 233); border-radius:10px;"> <b>   {{egruldata['rows'][0].n}}</b></td>

                      </tr>
                      <tr v-if="egruldata['rows'][0].g" >
                        <td class="pl-2 pr-2 pt-1 pb-1 " style="padding:2px;border-bottom:1px  dotted rgb(203, 201, 201);"> {{egruldata['rows'][0].g}}</td>

                      </tr>
                      <tr >
                        <td class="pl-2  pr-2 pt-1 pb-1 "   style= " border-bottom:1px  dotted rgb(203, 201, 201);"><b> ИНН:</b> {{egruldata['rows'][0].i}} <span v-if="egruldata['rows'][0].p"><b>КПП: </b>{{egruldata['rows'][0].p}}</span> </td>

                      </tr>


                      <tr>
                        <td class="pl-2 pt-1 pb-1 " style="border-bottom:1px  dotted rgb(203, 201, 201);"><b> ОГРН:</b> {{egruldata['rows'][0].o}}, <b>дата регистрации - </b> {{egruldata['rows'][0].r}}</td>

                      </tr>
                      <tr v-if="egruldata['rows'][0].a">
                        <td class="pl-2 pt-1 pb-1" style=" border-bottom:1px  dotted rgb(203, 201, 201);;"><b> Адрес:</b> {{egruldata['rows'][0].a}}</td>

                      </tr>

                      <tr >
                        <td class="pl-2 pt-2 pb-2  ;" style="">
                          <b style="color:#009d10">Выписка из ЕГРЮЛ - </b><a target="_blank" :href="egruldata['l']">скачать  </a>  </td>

                      </tr>




                    </table>
                  </td>



                </tr>
              </tbody>

          </table>
      </v-tab-item>
      <v-tab-item>

            <p v-if="(!girbozero) & (fnsdata.length>0)" style="color:#464646; font-weight:bold;" class="body-3   text-center   ml-5 mt-5">Сведения из Государственного информационного ресурса бухгалтерской (финансовой) отчетности<a target="_blank" style="text-decoration:none;text-shadow:none;  " href="https://bo.nalog.ru"><v-icon small>mdi-open-in-new</v-icon></a></p>


          <table v-if="fnsdata.length>0" cellspacing="7"  style=" margin:0px auto;margin-top:-10px;" :width="showwidth()>500?'100%':'90%'" >


              <tbody>
                <tr >


                  <td  style="box-shadow: 2px 2px 5px rgba(142, 139, 139, 0.45); border-radius:10px;">
                    <table class="text-caption"  width="100%" style="width:100%; page-break-inside:avoid; border:1px solid rgb(219, 219, 219); border-radius:10px;line-height:0.8rem ;">














                      <template v-for="item in fnsdata" style= "min-width:100%;" >

                        <span :key="item" style="display:block; border-bottom:3px  solid rgba(226, 226, 226, 0.57); ">

                          <tr  style="display:block; border-bottom:1px  dotted rgb(203, 201, 201); ">
                            <td  class="pl-4 pt-1 pb-1 pl-2"  style= " display:block; width:100%; " >
                              <b >Бух. отчётность за   {{ item['period']}} год от {{item.correction.datePresent.substring(8,10)}}.{{item.correction.datePresent.substring(5,7)}}.{{item.correction.datePresent.substring(0,4)}}<span v-if="item.correction.correctionVersion>0">, корректировка №{{item.correction.correctionVersion}}</span>
                              </b>-
                                <a target="_blank" :href="'https://bo.nalog.ru/download/bfo/pdf/'+fnscode+'?period='+item['period']+'&detailId='+item.correction['id']">скачать  </a>
                             </td>
                          </tr>



                        </span>





                      </template>




                    </table>
                  </td>



                </tr>
              </tbody>

          </table>






          <table v-if="girbozero" cellspacing="7" style=" margin:0px auto;margin-top:-20px;" :width="showwidth()>500?'100%':'90%'" >


              <tbody>
                <tr >



            <td  v-if="('inn' in fnsdata)==false" style="box-shadow: 2px 2px 5px rgba(142, 139, 139, 0.45); border-radius:10px;">
              <p class="ml-3 mr-3 mb-3 mt-3">Поиск по Государственному информационному ресурсу бухгалтерской (финансовой) отчетности произведён успешно.
              <b>Сведений в ГИР БО не найдено.</b></p>
            </td>



          </tr>
        </tbody>

        </table>
      </v-tab-item>
      <v-tab-item>


          <p v-if="'data' in mspdata" style="color:#464646; font-weight:bold;" class="body-3   text-center   ml-5 mt-5">Сведения из Единого реестра субъектов малого и среднего предпринимательства<a target="_blank" style="text-decoration:none;text-shadow:none;  " href="https://ofd.nalog.ru"><v-icon small>mdi-open-in-new</v-icon></a></p>



          <table v-if="'data' in mspdata" cellspacing="7"   style=" margin:0px auto;margin-top:-10px;" :width="showwidth()>500?'100%':'90%'">


              <tbody>
                <tr v-if="'data' in mspdata? mspdata['data'].length>0: false" >


                  <td  style="box-shadow: 2px 2px 5px rgba(142, 139, 139, 0.45); border-radius:10px;">
                    <table class="text-caption"  width="100%" style="page-break-inside:avoid;border:1px solid rgb(219, 219, 219); border-radius:10px;line-height:0.8rem ; margin:  0px;">

                      <tr >
                        <td class="pl-3 pr-2 pb-2 pt-2" style="border-bottom:1px  dotted rgb(203, 201, 201);  background:rgb(235, 233, 233); border-radius:10px;"> <b>   {{mspdata['data'][0]['name_ex']}}</b></td>

                      </tr>
                      <tr >
                        <td class="pl-2 pr-2 pt-1 pb-1 " style="padding:2px;border-bottom:1px  dotted rgb(203, 201, 201);padding:2px">Включено в реестр - {{mspdata['data']['0']['dtregistry']}}</td>

                      </tr>
                      <tr >
                        <td class="pl-2 pr-2 pt-1 pb-1 " style="padding:2px;border-bottom:1px  dotted rgb(203, 201, 201);"><b> ИНН:</b> {{mspdata['data']['0']['inn']}} <b>ОГРН: </b>{{mspdata['data']['0']['ogrn']}} </td>

                      </tr>


                      <tr>
                        <td class="pl-2" style="color:#009d10"><b> Категория:</b> {{mspdata['data']['0']['category']==1?'Микропредприятие':mspdata['data']['0']['category']==2?'Малое предприятие':'Cреднее предприятие'}}</td>

                      </tr>


                      <tr >
                        <td class="pl-2 pt-2 pb-2" style="">
                          <b style="color:#009d10">Выписка из ЕРСМСП - </b><a target="_blank" :href="'https://ofd.nalog.ru/excerpt.pdf?token='+mspdata['data']['0']['token']">скачать  </a>  </td>
                        </tr>


                        <tr >
                          <td class="pl-2 pt-0 pb-2" style="">
                            <b style="color:#009d10">Выписка о периодах нахождения в ЕРСМСП - </b><a target="_blank" :href="'https://ofd.nalog.ru/periods.pdf?token='+mspdata['data']['0']['token']">скачать  </a>  </td>

                      </tr>
                      <tr v-if="mspdata['data']['0']['is_pp']==1">
                        <td class="pl-2 pt-0 pb-2" style="">
                          <b style="color:#009d10">Сведения о предоставленной поддержке - </b><a target="_blank" :href="'https://rmsp-pp.nalog.ru/subject.html?id='+mspdata['data']['0']['inn']+'&id2='+mspdata['data']['0']['ogrn']">просмотреть  </a>  </td>

                    </tr>




                    </table>
                  </td>



                </tr>

                <tr >


                  <td  v-if="'data' in mspdata? mspdata['data'].length==0: false" style="box-shadow: 2px 2px 5px rgba(142, 139, 139, 0.45); border-radius:10px;">
                    <p class="ml-3 mr-3 mb-3 mt-3">Поиск по Единому реестру субъектов малого и среднего предпринимательства произведён успешно.
                    <b>Сведений в ЕРСМСП не найдено.</b></p>
                  </td>



                </tr>
              </tbody>

          </table>

      </v-tab-item>
      <v-tab-item>

         <p v-if="rosstatdata.length>0" style="color:#464646; font-weight:bold;" class="body-3   text-center   ml-5 mt-5">Сведения РОССТАТ о формах статистической отчетности по организации<a target="_blank" style="text-decoration:none; text-shadow:none; " href="https://websbor.gks.ru/online/info"><v-icon small>mdi-open-in-new</v-icon></a></p>

         <v-expansion-panels v-if="rosstatdata.length>0" style="margin-top:-10px"   >

           <v-expansion-panel>
             <v-expansion-panel-header class="text-align-left black--text" color="rgb(233, 232, 232)">

              Найдено форм: {{rosstatdata.length}}. Просмотреть...



             </v-expansion-panel-header>

             <v-expansion-panel-content>

         <table v-if="rosstatdata.length>0" cellspacing="7" style="margin-top:5px;"  width="100%"  >


             <tbody>
               <tr v-for="item in rosstatdata" :key="item" >


                 <td  style="box-shadow: 2px 2px 5px rgba(142, 139, 139, 0.45); border-radius:10px;">
                   <table class="text-caption"  width="100%" style="page-break-inside:avoid;border:1px solid rgb(219, 219, 219); border-radius:10px;line-height:0.8rem ; margin:  0px;">

                     <tr >
                       <td class="pl-3 pr-2 pb-2 pt-2" style="border-bottom:1px  dotted rgb(203, 201, 201);  background:rgb(235, 233, 233); border-radius:10px;"> <b>  {{item['index']}}</b></td>

                     </tr>
                     <tr >
                       <td class="pl-2 pr-2 pt-1 pb-1 " style="padding:2px;border-bottom:1px  dotted rgb(203, 201, 201);padding:2px"> {{item['name']}}</td>

                     </tr>
                     <tr >
                       <td class="pl-2 pr-2pt-1 pb-1 " style="padding:2px;border-bottom:1px  dotted rgb(203, 201, 201);"><b> Периодичность:</b> {{item['form_period']}}</td>

                     </tr>

                     <tr >
                       <td class="pl-2 pt-1 pb-1 " style="padding:2px;border-bottom:1px  dotted rgb(203, 201, 201);"> <b>Срок сдачи - </b>{{item['end_time']}} </td>
                     </tr>
                     <tr>
                       <td class="pl-2 pt-1 pb-1 " style="padding:2px;"><b> Отчетный период:</b> {{item['reported_period']}}</td>

                     </tr>




                   </table>
                 </td>



               </tr>
             </tbody>

         </table>

         </v-expansion-panel-content>
         </v-expansion-panel>
           </v-expansion-panels>
      </v-tab-item>

  </v-tabs-items>









</div>






</v-tab-item>

<v-tab-item >
  <p class="body-0 pr-4 pl-4 mb-4  text-justify" style=" line-height:1.2rem; color:black; border:1px solid rgb(236, 234, 234); width:95%; margin:0px auto; padding:15px; border-radius:15px; margin-top:45px; ">

  <v-icon   class="mr-2" color="grey darken-3" style="text-shadow:0px 0px 1px rgb(255,255,255)" >mdi-web</v-icon>Онлайн-платформа по разработке программ энергосбережения и повышения энергоэффективности для регулируемых организаций.
    </p>
  <p class="body-0 pr-4 pl-4 mb-4 mt-4 text-justify" style=" line-height:1.2rem; color:black;  border:1px solid rgb(236, 234, 234); width:95%; margin:0px auto; padding:15px; border-radius:15px; margin-top:45px; ">
    <span color="grey darken-3" ><v-icon color="grey darken-3"   style="text-shadow:0px 0px 1px rgb(255, 255, 255);transform:rotate(15deg); "   class="mr-2" >mdi-lightning-bolt</v-icon>Создайте свою программу энергосбережения и повышения энергоэффективности, а также отчёт к ней онлайн и бесплатно.</span></p>

    <div class="text-center ;" style=" margin-left:-20px; margin-right:-20px;">
      <v-btn large    class=" white--text mr-1 ml-1  mt-2 mb-0  " color="rgba(0, 157, 16, 0.8)" href="https://261.рф" style=" ;border:2px solid rgba(12, 69, 14, 0.32)"><v-icon large color="#ffffff" class="mr-2" >mdi-arrow-right</v-icon>ПЕРЕЙТИ: 261.рф<v-icon large color="#ffffff" class="mr-2" >mdi-arrow-right</v-icon></v-btn>

    </div>


<p></p>
</v-tab-item>
<v-tab-item>

  <p class="body-0 pr-4 pl-4 mb-4 mt-4 text-justify" style=" line-height:1.2rem; color:black;  border:1px solid rgb(236, 234, 234); width:95%; margin:0px auto; padding:15px; border-radius:15px; margin-top:45px; ">
    <span color="grey darken-3" ><v-icon color="grey darken-3"   style="text-shadow:0px 0px 1px rgb(255, 255, 255);transform:rotate(15deg); "   class="mr-2" >mdi-lightning-bolt</v-icon>Веб-приложение позволяет суммировать значения ежемесячных форм 46-ТЭ формата EIAS в годовую форму.</span></p>

    <div class="text-center ;" style=" margin-left:-20px; margin-right:-20px;">
      <v-btn large    class=" white--text mr-1 ml-1  mt-2 mb-0  " color="rgba(0, 157, 16, 0.8)" href="https://46.btr-online.ru" style=" ;border:2px solid rgba(12, 69, 14, 0.32)"><v-icon large color="#ffffff" class="mr-2" >mdi-arrow-right</v-icon>ПЕРЕЙТИ: 46.btr-online.ru<v-icon large color="#ffffff" class="mr-2" >mdi-arrow-right</v-icon></v-btn>

    </div>


<p></p>
</v-tab-item>
</v-tabs-items>

<v-dialog v-model="settings"  max-width="800" :fullscreen="showwidth()<500" >
            <v-card >

              <v-card-text>

                <p class="text-bold text-center text-overline" style="margin-left:-15px;margin-right:-15px; width:100%; ">Выделите Виды деятельности
                  <br/>


    <v-btn   outlined  small :color="te?'deep-purple accent-4':'grey'" @click="te=!te" v-model="te"    style="height:45px;max-width:45px; padding:-5px ;border-radius:8px; margin:3px;">

      <span class="caption">ТЭ</span>
    </v-btn>
    <v-btn   outlined  small :color="gvs?'deep-purple accent-4':'grey'" @click="gvs=!gvs"  v-model="gvs"   style="height:45px;max-width:45px; padding:-5px;border-radius:8px; margin:3px; ">

      <span class="caption">ГВС</span>
    </v-btn>



    <v-btn   outlined  small :color="vs?'deep-purple accent-4':'grey'" @click="vs=!vs"  v-model="vs"   style="height:45px;max-width:45px; padding:-5px;border-radius:8px; margin:3px; ">

      <span class="caption">ВС</span>
    </v-btn>






    <v-btn   outlined  small :color="ee?'deep-purple accent-4':'grey'" @click="ee=!ee"  v-model="ee"   style="height:45px;max-width:45px; padding:-5px;border-radius:8px; margin:3px;">

      <span class="caption">Пер.ЭЭ</span>
    </v-btn>
    <v-btn   outlined  small :color="vo?'deep-purple accent-4':'grey'" @click="vo=!vo"  v-model="vo"   style="height:45px;max-width:45px; padding:-5px;border-radius:8px; margin:3px;">

      <span class="caption">ВО</span>
    </v-btn>
<br/>
    <v-btn   outlined  small :color="trvo?'deep-purple accent-4':'grey'" @click="trvo=!trvo"  v-model="trvo"   style="height:45px;max-width:45px; padding:-5px;border-radius:8px; margin:3px; ">

      <span class="caption">ТР.ВО</span>
    </v-btn>
    <v-btn   outlined  small :color="trvs?'deep-purple accent-4':'grey'" @click="trvs=!trvs"  v-model="trvs"   style="height:45px;max-width:45px; padding:-5px;border-radius:8px; margin:3px; ">

      <span class="caption">ТР.ВС</span>
    </v-btn>
    <v-btn   outlined  small :color="trte?'deep-purple accent-4':'grey'" @click="trte=!trte"  v-model="trte"   style="height:45px;max-width:45px; padding:-5px;border-radius:8px;  margin:3px;">

      <span class="caption">ТР.ТЭ</span>
    </v-btn>
    <v-btn   outlined  small :color="tn?'deep-purple accent-4':'grey'" @click="tn=!tn"  v-model="tn"   style="height:45px;max-width:45px; padding:-5px;border-radius:8px; margin:3px; ">

      <span class="caption">ТН</span>
    </v-btn>



                </p>
              <p class="text-right mb-4" style="margin-top:-55px">
                <v-btn  icon outlined fab  x-small color="grey" @click="helpdialog=true"     >
              <v-icon>  mdi-help </v-icon>
                    </v-btn>

              </p>


<v-divider />

                <table cellpadding=0 cellspacing=0 width="100%" style="max-width:800px;">
                  <tr >
                    <td style="border-bottom:1px solid rgb(233, 233, 233)" class="text-center">До 15 сотрудников в штате</td>
                    <td style="border-bottom:1px solid rgb(233, 233, 233)">
                      <v-switch :false-value="false" :true-value="true"
                      color="deep-purple accent-3"
                      class="pl-2"

                      :value="ot15"
                      v-model="ot15"
                        @click="do15=!do15;"

                    style=""
                ></v-switch></td>
                    <td  class=" text-center" style="border-bottom:1px solid rgb(233, 233, 233)">В штате более 15 сотрудников </td>
                  </tr>

                  <tr>
                    <td style="border-bottom:1px solid rgb(233, 233, 233)" class=" text-center">Малое предприятие</td>
                    <td style="border-bottom:1px solid rgb(233, 233, 233)" >
                      <v-switch :false-value="false" :true-value="true"
                      color="deep-purple accent-3"
                      :value="nemp"
                      v-model="nemp"
                        @click="mp=!mp;"
                      class="pl-2"
                  style=""
                ></v-switch></td>
                    <td  style="border-bottom:1px solid rgb(233, 233, 233)" class="text-center ">Не является малым предприятием</td>
                  </tr>


                  <tr>
                    <td style="border-bottom:1px solid rgb(233, 233, 233)" class=" text-center">Организация не сдаёт форму П4</td>
                    <td style="border-bottom:1px solid rgb(233, 233, 233)" >
                      <v-switch :false-value="false" :true-value="true"
                      color="deep-purple accent-3"
                      :value="p4"
                      v-model="p4"
                        @click="nep4=!nep4;  "
                      class="pl-2"
                    style=""
                ></v-switch></td>
                    <td  style="border-bottom:1px solid rgb(233, 233, 233)" class="text-center ">Организация сдаёт форму П4</td>
                  </tr>

                <tr v-if="te||tn">
                  <td style="border-bottom:1px solid rgb(233, 233, 233)" class="  text-center">Нерегулируемое тепло (в т.ч. ценовые зоны)</td>
                  <td  style="border-bottom:1px solid rgb(233, 233, 233)">
                    <v-switch :false-value="false" :true-value="true"
                    color="deep-purple accent-3"
                    class="pl-2"
                  style=""
                  :value="tereg"
                  v-model="tereg"
                    @click="tenereg=!tenereg;  "
              ></v-switch></td>
                  <td style="border-bottom:1px solid rgb(233, 233, 233)"  class=" text-center"> Регулируемые тарифы на тепловую энергию</td>
                </tr>

                <tr>
                  <td style="border-bottom:1px solid rgb(233, 233, 233)" class=" text-center">Упрощеная система налогообложения</td>
                  <td style="border-bottom:1px solid rgb(233, 233, 233)" >
                    <v-switch :false-value="false" :true-value="true"
                    color="deep-purple accent-3"
                    class="pl-2"
                  style="margin-bottom:10px"
                  :value="osno"
                  v-model="osno"
                    @click="usno=!usno;  "
              ></v-switch></td>
                  <td style="border-bottom:1px solid rgb(233, 233, 233)"  class=" text-center">Основная система налогообложения</td>
                </tr>

                  <tr>
                    <td class=" text-center">Бюджетный учет в государственной организации</td>
                    <td>
                      <v-switch :false-value="false" :true-value="true"
                      color="deep-purple accent-3"
                      class="pl-2"
                  style=""

                    :value="nebudg"
                    v-model="nebudg"

                    @click="budg=!budg;  "

                ></v-switch></td>
                    <td  class=" text-center">Бюджетный учет не применяется </td>
                  </tr>
                </table>



                <v-divider />
                <br/>
                <p class="text-right">
                  <v-btn class="mr-5" color="grey accent-3 white--text text-right" @click="$cookies.remove('te'); refresh() ;settings = false;">
                    Сбросить

                  </v-btn>

                  <v-btn  color="deep-purple accent-3 white--text text-right" @click="settings = false;savecookie();">
                    Сохранить

                  </v-btn>

</p>

              </v-card-text>


            </v-card>

          </v-dialog>

          <v-dialog v-model="helpdialog"   max-width="800" :fullscreen="showwidth()<500" >
                      <v-card >

                        <v-card-text>

                          <p class="text-bold text-center text-overline" style="margin-left:-15px;margin-right:-15px; width:100%; ">Справка

                            </p>


<table style=" margin-top:-20px ">



  <tr>
    <td><v-btn   outlined  small color="grey "    style="height:45px;max-width:45px; padding:-5px ;;border-radius:8px; margin:1px;">
      <span class="caption">ТЭ</span>
    </v-btn></td>
    <td class="body-2"> Продажа тепловой энергии абонентам</td>
  </tr>
  <tr>
    <td><v-btn   outlined  small color="grey "    style="height:45px;max-width:45px; padding:-5px ;;border-radius:8px; margin:1px;">
      <span class="caption">ГВС</span>
    </v-btn></td>
    <td class="body-2"> Оказание услуги по горячему водоснабжению абонентам</td>
  </tr>
  <tr>
    <td><v-btn   outlined  small color="grey "    style="height:45px;max-width:45px; padding:-5px ;;border-radius:8px; margin:1px;">
      <span class="caption">ВС</span>
    </v-btn></td>
    <td class="body-2"> Оказание услуги по водоснабжению абонентам</td>
  </tr>
  <tr>
    <td><v-btn   outlined  small color="grey "    style="height:45px;max-width:45px; padding:-5px ;;border-radius:8px; margin:1px;">
      <span class="caption">Пер.ЭЭ</span>
    </v-btn></td>
    <td class="body-2"> Передача электроэнергии</td>
  </tr>
  <tr>
    <td><v-btn   outlined  small color="grey "    style="height:45px;max-width:45px; padding:-5px ;;border-radius:8px; margin:1px;">
      <span class="caption">ВО</span>
    </v-btn></td>
    <td class="body-2"> Оказание услуги по водоотведению абонентам</td>
  </tr>
  <tr>
    <td><v-btn   outlined  small color="grey "    style="height:45px;max-width:45px; padding:-5px ;;border-radius:8px; margin:1px;">
      <span class="caption">ТР.ВО</span>
    </v-btn></td>
    <td class="body-2"> Транспортировка стоков для гарантирующей организации</td>
  </tr>

  <tr>
    <td><v-btn   outlined  small color="grey "    style="height:45px;max-width:45px; padding:-5px ;;border-radius:8px; margin:1px;">
      <span class="caption">ТР.ВС</span>
    </v-btn></td>
    <td class="body-2"> Транспортировка воды для гарантирующей организации</td>
  </tr>
  <tr>
    <td><v-btn   outlined  small color="grey "    style="height:45px;max-width:45px; padding:-5px ;;border-radius:8px; margin:1px;">
      <span class="caption">ТР.ТЭ</span>
    </v-btn></td>
    <td class="body-2"> Транспортировка тепловой энергии для ЕТО</td>
  </tr>
  <tr>
    <td><v-btn   outlined  small color="grey "    style="height:45px;max-width:45px; padding:-5px ;;border-radius:8px; margin:1px;">
      <span class="caption">ТН</span>
    </v-btn></td>
    <td class="body-2"> Продажа теплоносителя абонентам</td>
  </tr>
  <tr>
    <td colspan="2" class="body-2"> <b>Нерегулируемое тепло</b> - договорные тарифы на тепловую энергию или на теплоноситель по соглашению сторон, а также тарифы в ценовых зонах теплоснабжения. </td>
  </tr>
  <tr>
    <td colspan="2" class="body-2"> <b>Бюджетный учёт</b> - учёт в государственных бюджетофинансируемых учреждениях (не путать с МУП).</td>
  </tr>

</table>

        <p class="text-right">
                            <v-btn  color="deep-purple accent-3  mt-1 white--text text-right" @click="helpdialog = false">
                              закрыть

                            </v-btn>
                          </p>


                        </v-card-text>


                      </v-card>

                    </v-dialog>



                    <v-dialog v-model="ridialog"   max-width="800" :fullscreen="showwidth()<500" >
                                <v-card >

                                  <v-card-text>

                                    <p class="text-bold text-center text-overline" style="margin-left:-15px;margin-right:-15px; width:100%; ">Раскрытие информации

                                      </p>

<vue-json-to-table :data="RIinfo"></vue-json-to-table>

                  <p class="text-right">
                                      <v-btn  color="deep-purple accent-3  mt-1 white--text text-right" @click="ridialog = false">
                                        закрыть

                                      </v-btn>
                                    </p>


                                  </v-card-text>


                                </v-card>

                              </v-dialog>



      <p class="body-2" align="center" style="color:black;border-top:1px solid #009d10;   width:100%; margin:0px auto; padding:10px;padding-top:15px; line-height:1.2rem ">
        ООО «Бюро тарифных расчетов»
    <br>


      e-mail: <a style="color:black;" href="mailto:info@btr-online.ru">info@btr-online.ru</a>
    <br>
    <a style="color:black;" href="tel:+78462216058">8 (846) 221-60-58</a>
    <br>
         <a style="color:black;" href="http://btr-online.ru">btr-online.ru</a>
      </p>

    </v-main>


  </v-app>


</template>



<script>
  // Enable pusher logging - don't include this in production
  //Pusher.logToConsole = true;



  const axios = require('axios').default


  //import draggable from "vuedraggable"
  import Vue from 'vue';
  import VueCookies from 'vue-cookies';
  import VueJsonToTable from 'vue-json-to-table';


  Vue.use(VueCookies);
  Vue.use(VueJsonToTable);














  //import html2canvas from 'html2canvas';

  Vue.filter('kb', val => {
    return Math.floor(val / 1024);
  });

  export default {
    components: {


      //draggable,
    },
    props: {
      source: String,
    },
    data: () => ({
      god_rabot_filter:"",
      rstcod:'',
      ridialog:false,
      RIinfo:[],
      UPDprogress:false,
      RI:[],
      RItablo:[],
      rizero:false,
      ri_caption:{'Теплоснабжение': 'Теплоснабжение', 'Холодное водоснабжение':'Водоснабжение','Водоотведение':'Водоотведение','Горячее водоснабжение':'ГВС','TKO':'ТКО'},
      ri_keys:{'Теплоснабжение': 'Теплоснабжение', 'Водоснабжение':'Холодное водоснабжение','Водоотведение':'Водоотведение','ГВС':'Горячее водоснабжение','TKO':'ТКО'},
      RIprogress:false,
      firms:[],
      prikazieedata:[],
      full:'',
      short:'',
      mo:'',
      prikaziprogress:false,
      printvisible:false,
      girbozero:false,
      ermspprogress:false,
      egrulprogress:false,
      rosstatprogress:false,
      fnsprogress:false,
      err:{message:''},
      egruldata:[],
      mspdata:[],
      fnsdata:[],
      fnscode:'',
      rosstatdata:[],
      prikazidata:[],
      inn:"",
      tab1:null,
      tab3:null,
      tab55:null,
      te:true,
      trte:false,
      tn:false,
      gvs:false,
      vs:true,
      vo:true,
      trvs:false,
      trvo:false,
      ee:true,
      osno:true,
      usno:false,
      nebudg:true,
      budg:false,
      nemp:true,
      mp:false,
      nep4:false,
      p4:true,
      do15:false,
      ot15:true,
      tereg:true,
      tenereg:false,
      helpdialog:false,
      vd:['te',	'tn',	'gvs',	'vs',	'vo',	'trte',	'trvs',	'trvo',	'ee',	'osno',	'usno',	'nebudg',	'budg',	'nemp',	'mp',	'nep4',	'p4',	'do15',	'ot15',	'tereg',	'tenereg'],
      vdtext:['Теплоснабжение',	'Теплоноситель',	'ГВС',	'Водоснабжение',	'Водоотведение',	'Транспортировка тепловой энергии',	'Транспортировка воды',	'Транспортировка стоков',	'Передача электроэнергии',	'osno',	'usno',	'nebudg',	'budg',	'nemp',	'mp',	'nep4',	'p4',	'do15',	'ot15',	'tereg',	'tenereg'],
      nalog:[],

      months:['Отчёты и документы с изменяющимися датами сдачи','Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
      monthshort:['','янв', 'фев', 'мар', 'апр', 'мая', 'июн', 'июл', 'авг', 'сен', 'окт', 'ноя', 'дек'],
      calendar:[],
      sendingprocessdown:false,
      download:false,
      flagnow:false,
      recordobj:'',
      overlayer:false,

      flag_stop_redact:0,

                 ch1:[{message:''}],



      emailadd: false,
      sending: false,
      word: '',
      about: false,
      balance_upload: 0,
      commentaddindex: '',
      commentaddmsg: '',
      commentadd: false,

      showallperechen: true,
      tomsize_font: '16',
      expanded: [],

      tab: null,
      sel_reestr: "",
      per_direction: 2,
      centerlabel: true,
      sel_delo: {},
      sel_podacha: {},
      kind_titul: 'T',
      scale: 5,
      search: "",
      search1: "",
      move_doc: false,
      move_flag: false,
      dialog_edit_text: "",
      doc: [],
      list_dela: [],
      podacha1: {},
      files: [],
      output: null,
      org_height: 120,
      settings: false,
      dialog_titul: false,

      dialog_perechen: false,
      notice_print: false,
      label_print: false,
      label_count: 2,
      label_fontsize: 16,
      label_width: 200,
      label_height: 55,
      label_tom: " ",
      settings_pod: false,
      snackbarOK: false,
      titul_text: "",
      pd: {},
      snackbarErr: false,
      oktext: '',
      errtext: '',
      id: '0',
      show_ov_1: false,
      docs_over: [],
      show_ov_2: false,
      show_ov_3: false,
      eias_over: [],
      files_over: [],
      reestr_over: [],
      kods: [],
      file_links: [],
      index: null,
      drawer: null,
      dis: false,
      show: false,
      stroka_vivod: "",
      orgs: false,
      notes: false,
      jobs: false,
      navigation: {
        shown: true,
        width: 500,
        borderSize: 3
      },




    }),
    watch: {


    },


    computed: {







      direction() {
        return this.navigation.shown === false ? "Open" : "Closed";
      },







    },

    methods: {

      array_unique(arr)

      {

        return [...new Set(arr)]
      },




      printoutcalendar()
      {
                //
                // let divToPrint = window.document.getElementById("calendarprint");
                // let newWin = window.open('');
                // newWin.document.write(divToPrint.outerHTML);
                // newWin.print();



                window.setTimeout(() => {
                const prtHtml = document.getElementById('calendarprint').innerHTML;

                // Get all stylesheets HTML
                let stylesHtml = '';

                for (const node of [...document.querySelectorAll('link[rel="stylesheet"]')]) {
                  stylesHtml += node.outerHTML;
                }


                // Open the print window

                const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');

                WinPrint.document.write(`<!DOCTYPE html>
                <html>
                  <head>
                    ${stylesHtml}
                  </head>
                  <body style="background: rgb(255, 255, 255);">
                    ${prtHtml}
                  </body>
                </html>`);



                  WinPrint.document.close();
                  WinPrint.focus();

                  window.setTimeout(() => {WinPrint.focus(); WinPrint.print()}, 2000)
                  this.printvisible=false;
                }, 1000)
                  //WinPrint.print();
                  //WinPrint.close();



            },

      validateInn(inn, error) {
 var result = false;
 if (typeof inn === 'number') {
   inn = inn.toString();
 } else if (typeof inn !== 'string') {
   inn = '';
 }
 if (!inn.length) {

   error.message = 'ИНН пуст';
 } else if (/[^0-9]/.test(inn)) {

   error.message = 'ИНН может состоять только из цифр';
 } else if ([10, 12].indexOf(inn.length) === -1) {

   error.message = 'ИНН может состоять только из 10 или 12 цифр';
 } else {
   var checkDigit = function (inn, coefficients) {
     var n = 0;
     for (var i in coefficients) {
       n += coefficients[i] * inn[i];
     }
     return parseInt(n % 11 % 10);
   };
   switch (inn.length) {
     case 10:
       var n10 = checkDigit(inn, [2, 4, 10, 3, 5, 9, 4, 6, 8]);
       if (n10 === parseInt(inn[9])) {
         result = true;
       }
       break;
     case 12:
       var n11 = checkDigit(inn, [7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
       var n12 = checkDigit(inn, [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
       if ((n11 === parseInt(inn[10])) && (n12 === parseInt(inn[11]))) {
         result = true;
       }
       break;
   }
   if (!result) {

     error.message = 'Неправильное контрольное число';
   }
 }
 if (result)
 {
   this.girbozero=false;this.rizero=false;this.egruldata=[];this.mspdata={'data':[]};this.fnsdata=[];this.rosstatdata=[]; this.prikazieedata=[]; this.full=''; this.short='';this.mo=''
   this.prikazidata=[]; this.RI=[]; this.getRI();this.getRItab();this.rosstat();this.girbo();this.egrul();this.msp(); this.prikaziee();this.getRSTCOD();
   this.err.message=""
 }
 else {
   this.err.message=error.message
 }

 return result;
},

      rosstat()
      {
        var self = this;
        self.rosstatprogress=true;
        axios.post(
            'https://calendar.btr-online.ru/Getrosstat',
            JSON.stringify({
                enter: "123",
                inn:self.inn,
            })
          )
          .then(function(response) {
            if (
              response.data != "ERROR") {
                self.rosstatdata=response.data;
                self.rosstatprogress=false;

            } else {
              self.rosstatprogress=false;
            }
          })
          .catch(function(error) {
            window.console.log(error);
            self.rosstatprogress=false;
          });
      },


            prikazi()
            {



              var self = this;
              var short='--';
              var full='--';
              var mo="--";
              self.prikaziprogress=true;

              if (self.short!='')
              {
                if (self.short.indexOf('"')>-1)
                {
                  short='«'+self.short.match(/"(.+)"/)[1]+'»'
                }
                else {
                  short='«'+self.short+'»'
                }

              }

              if (self.full!='')
              {
                if (self.full.indexOf('"')>-1)
                {
                  full='«'+self.full.match(/"(.+)"/)[1]+'»'
                }
                else {
                  full='«'+self.full+'»'
                }

              }

              if(self.fnsdata.okopf.name.indexOf('Муниц')>-1)
              {

                if (self.fnsdata.city)
                {
                  mo=self.fnsdata.city.slice(0,-3)
                }
                else {
                  mo=self.fnsdata.district.slice(0,-3)
                }



              }

              axios.post(
                  'https://calendar.btr-online.ru/prikazi',
                  JSON.stringify({
                      enter: "123",
                      inn:self.inn,
                      short: short,
                      mo:mo,
                      full:  full,
                  })
                )
                .then(function(response) {
                  if (
                    response.data != "ERROR") {
                      self.prikazidata=response.data;
                      self.prikaziprogress=false;

                  } else {
                    self.prikaziprogress=false;
                  }
                })
                .catch(function(error) {
                  window.console.log(error);
                  self.prikaziprogress=false;
                });
            },
            prikazi2()
            {



              window.setTimeout(() => {



                var self = this;
                var short='--';
                var full='--';
                var mo='--';

                this.log('prikazi2');
                self.prikaziprogress=true;
                if (self.egruldata)
                {

                  if ((self.egruldata['rows'][0].k=='fl'))

                  {


                    self.short=self.egruldata['rows'][0].n.split(" ")[0].slice(0,-2);
                    self.full=self.egruldata['rows'][0].n.split(" ")[1].slice(0,1)+'.'+self.egruldata['rows'][0].n.split(" ")[2].slice(0,1)+'.';
                    self.prikazi3();
                    return;


                  }

                 else {



                   if (self.egruldata['rows'][0].c!='')
                   {
                     if (self.egruldata['rows'][0].c.indexOf('"')>-1)
                     {
                       short='«'+self.egruldata['rows'][0].c.match(/"(.+)"/)[1]+'»'
                     }
                     else {
                       short='«'+self.egruldata['rows'][0].c+'»'
                     }

                   }

                   if (self.egruldata['rows'][0].n!='')
                   {
                     if (self.egruldata['rows'][0].n.indexOf('"')>-1)
                     {
                       full='«'+self.egruldata['rows'][0].n.match(/"(.+)"/)[1]+'»'
                     }
                     else {
                       full='«'+self.egruldata['rows'][0].n+'»'
                     }

                   }

                 }
               }






                axios.post(
                    'https://calendar.btr-online.ru/prikazi',
                    JSON.stringify({
                        enter: "123",
                        inn:self.inn,
                        short:short,
                        mo:mo,
                        full: full,

                    })
                  )
                  .then(function(response) {
                    if (
                      response.data != "ERROR") {
                        self.prikazidata=response.data;
                        self.prikaziprogress=false;

                    } else {
                      self.prikaziprogress=false;
                    }
                  })
                  .catch(function(error) {
                    window.console.log(error);
                    self.prikaziprogress=false;
                  });


              }, 3000)


            },

            prikazi3()
            {
              var self = this;
              self.prikaziprogress=true;

              axios.post(
                  'https://calendar.btr-online.ru/prikaziip',
                  JSON.stringify({
                      enter: "123",
                      inn:self.inn,
                      short: self.short,
                      full:  self.full
                  })
                )
                .then(function(response) {
                  if (
                    response.data != "ERROR") {
                      self.prikazidata=response.data;
                      self.prikaziprogress=false;

                  } else {
                    self.prikaziprogress=false;
                  }
                })
                .catch(function(error) {
                  window.console.log(error);
                  self.prikaziprogress=false;
                });
            },
            prikaziee()
            {
              var self = this;
              self.prikaziprogress=true;

              axios.post(
                  'https://calendar.btr-online.ru/prikaziee',
                  JSON.stringify({
                      enter: "123",
                      inn:self.inn,

                  })
                )
                .then(function(response) {
                  if (
                    response.data != "ERROR") {
                      self.prikazieedata=response.data;
                      self.prikaziprogress=false;

                  } else {
                    self.prikaziprogress=false;
                  }
                })
                .catch(function(error) {
                  window.console.log(error);
                  self.prikaziprogress=false;
                });
            },
            getRI()
            {
              var self = this;
              var act=[];
              self.RIprogress=true;
              self.getRSTCOD();

              axios.post(
                  'https://calendar.btr-online.ru/GetRINFO',
                  JSON.stringify({
                      enter: "123",
                      inn:self.inn,

                  })
                )
                .then(function(response) {
                  if (
                    response.data != "ERROR") {
                      self.RI=response.data;
                      self.RIprogress=false;
                      if (self.RI.length===0)
                      {
                          self.rizero=true;

                      }
                      else {
                        act=self.array_unique(self.RI.map(el=>(el.sphere)))

                          self.te=false;
                          self.gvs=false;
                          self.vo=false;
                          self.vs=false;







                        act.forEach((item) => {

                          if (item==="Теплоснабжение")
                          {
                            self.te=true

                          }



                          if (item==="Горячее водоснабжение")
                          {
                            self.gvs=true;

                          }



                          if (item==="Водоотведение")
                          {
                            self.vo=true;

                          }



                          if (item==="Холодное водоснабжение")
                          {
                            self.vs=true;

                          }



                        });
                      }

                  } else {
                    self.RIprogress=false;




                  }
                })
                .catch(function(error) {
                  window.console.log(error);
                  self.RIprogress=false;
                });
            },

            getRItab()
            {
              var self = this;
              self.RIprogress=true;

              axios.post(
                  'https://calendar.btr-online.ru/GetRINFOtablo',
                  JSON.stringify({
                      enter: "123",
                      inn:self.inn,

                  })
                )
                .then(function(response) {
                  if (
                    response.data != "ERROR") {
                      self.RItablo=response.data;
                      self.RIprogress=false;
                      if (self.RItablo.length===0)
                      {
                          self.rizero=true;

                      }

                  } else {
                    self.RIprogress=false;

                  }
                })
                .catch(function(error) {
                  window.console.log(error);
                  self.RIprogress=false;
                });
            },

            getRSTCOD()
            {
              var self = this;


              axios.post(
                  'https://calendar.btr-online.ru/GetRSTCOD',
                  JSON.stringify({
                      enter: "123",
                      inn:self.inn,

                  })
                )
                .then(function(response) {
                  if ((response.data != "ERROR") && (response.data!='')) {
                      self.rstcod=response.data[0].code;

                  } else {

                    self.rstcod=''

                  }
                })
                .catch(function(error) {
                  window.console.log(error);

                });
            },

            updateeias()
            {
              var self = this;
              this.UPDprogress=true;

              var cd=self.rstcod;
              self.rstcod='';
              axios.post(
                  'https://calendar.btr-online.ru/updateeias',
                  JSON.stringify({
                      enter: "123",
                      rstorgid:cd,

                  })
                )
                .then(function(response) {
                  if (response.data) {
                    self.getRI();
                    self.getRItab();
                    self.UPDprogress=false;


                  }
                })
                .catch(function(error) {
                  window.console.log(error);
                  self.UPDprogress=false;

                });
            },


  padTo2Digits(num) {
  return num.toString().padStart(2, '0');
},

     formatDateTime(date) {
       date=new Date(date);
  return   (this.padTo2Digits(date.getDate())+
  '/'+ this.padTo2Digits(date.getMonth() + 1)+
  '/'+ date.getFullYear()+
  " "+this.padTo2Digits(date.getHours())+
  ":"+this.padTo2Digits(date.getMinutes())+
  ":"+ this.padTo2Digits(date.getSeconds()))},

  formatDate(date) {
    date=new Date(date);
return   (this.padTo2Digits(date.getDate())+
'/'+ this.padTo2Digits(date.getMonth() + 1)+
'/'+ date.getFullYear())},

          rireq(sphere, year, inn, kindri)
                  {
                    var self = this;
                    var el=[];
                    self.RIinfo = [];
                    axios.post(
                      'https://calendar.btr-online.ru/GetRINFOdetails',
                      JSON.stringify({
                        enter: "123",
                        inn: inn,
                        year: year,
                        sphere: sphere,
                        kindri: kindri

                      })
                    )
                      .then(function(response) {
                        if (
                          response.data != "ERROR") {


                          switch (kindri) {
                            case 'ORG':
                                self.RIinfo = response.data.map((elem,index)=>({'Номер':index+1,'Отчёт сдан':self.formatDateTime(elem.data_publ)+'('+elem.form_name+')'}));
                              break;
                            case 'REQUEST':
                            el= response.data.map((elem)=>(elem.MR+ ': Заявление № '+elem.nomer_doc +', от '+self.formatDate(elem.data_doc)+', отчёт сдан '+self.formatDateTime(elem.data_publ)));
                              self.RIinfo =[...new Set(el)];
                              break;
                            case 'PRICE':
                            el= response.data.map((elem)=>(elem.MR+ ': Приказ - '+elem.nomer_doc+', отчёт сдан '+self.formatDateTime(elem.data_publ) ));
                              self.RIinfo =[...new Set(el)];
                              break;
                            case 'BALANCE':
                            el= response.data.map((elem)=>(elem.MR+', отчёт сдан '+self.formatDateTime(elem.data_publ) ));
                              self.RIinfo =[...new Set(el)];
                                break;
                            case 'QUARTER':
                            el= response.data.map((elem)=>(elem.MR+ ': I кв.- '+elem.q1 + ', II кв.- '+elem.q2+', III кв.- '+elem.q3+', IV кв.- '+elem.q4+', отчёт сдан '+self.formatDateTime(elem.data_publ)));
                              self.RIinfo =[...new Set(el)];
                              break;
                            case 'QUARTER (LIMIT)':
                            el= response.data.map((elem)=>(elem.MR+ ': I кв.- '+elem.limit1 + ', II кв.- '+elem.limit2+', III кв.- '+elem.limit3+', IV кв.- '+elem.limit4+', отчёт сдан '+self.formatDateTime(elem.data_publ)));
                              self.RIinfo =[...new Set(el)];
                              break;
                            case 'TERMS':
                            el= response.data.map((elem)=>(elem.MR+', отчёт сдан '+self.formatDateTime(elem.data_publ) ));
                              self.RIinfo =[...new Set(el)];
                              break;
                            case 'INVEST':
                            el= response.data.map((elem)=>(elem.MR+ ', отчёт сдан '+self.formatDateTime(elem.data_publ) ));
                              self.RIinfo =[...new Set(el)];
                              break;

                            default:
                              //
                              break;
                          }


                        //  self.RIinfo = response.data;



                        }
                      })
                      .catch(function(error) {
                        window.console.log(error);

                      });
                  },

      girbo()
      {
        var self = this;
        self.fnsprogress=true;
        axios.post(
            'https://calendar.btr-online.ru/girbo',
            JSON.stringify({
                enter: "123",
                inn:self.inn,
            })
          )
          .then(function(response) {
            if (response.data != "ERROR") {
                self.fnsdata=JSON.parse(response.data.split('----')[0]).sort(( a, b ) => a.period > b.period ? -1 : 1 );
                self.fnscode=JSON.parse(response.data.split('----')[1])
                self.fnsprogress=false;
                self.full=self.fnsdata['fullName'];
                self.short=self.fnsdata['shortName'];
                self.prikazi()

            } else {
            self.fnsprogress=false;
            self.girbozero=true;
            self.prikazi2();



            }
          })
          .catch(function(error) {
            window.console.log(error);
            self.fnsprogress=false;
            self.prikazi2();

          });
      },

      egrul()
      {
        var self = this;
        self.egrulprogress=true;
        axios.post(
            'https://calendar.btr-online.ru/egrul',
            JSON.stringify({
                enter: "123",
                inn:self.inn,
            })
          )
          .then(function(response) {
            if (
              response.data != "ERROR") {
                self.egruldata=response.data;
                self.egrulprogress=false;

            } else {

              self.egrulprogress=false;
            }
          })
          .catch(function(error) {
            window.console.log(error);
            self.egrulprogress=false;
          });
      },
      msp()
      {
        var self = this;
        self.ermspprogress=true;
        axios.post(
            'https://calendar.btr-online.ru/Getmsp',
            JSON.stringify({
                enter: "123",
                inn:self.inn,
            })
          )
          .then(function(response) {


            try {


            if ( (  response.data !== "ERROR") && (('ERROR' in response.data)==false)  ) {
                self.mspdata=response.data;
                self.ermspprogress=false;

            } else
            {
              self.ermspprogress=false;
            }
          }
            catch(error) {
              window.console.log(error);
              self.ermspprogress=false;
            }
          })

      },

      refresh() {
    window.location.reload();
},
       convertToBoolean(sss) {
          try {
              return JSON.parse(sss.toLowerCase());
          }
          catch (e) {
              return undefined;
          }
      },

      savecookie()
      {

          this.vd.forEach((item) => {
              this.$cookies.set(item, this[item],31556926);
          });

      },


showwidth()
{
  return window.document.documentElement.clientWidth
},


      reportfilter(elem)
      {
        var  result=false;



     for (var i = 2; i < 9; i++) {
        result=result || (((this[this.vd[i]]+0)+(elem[this.vd[i]]+0))===2)

     }


     if ((this['te']+0)===1)
     {
         if ((this['tereg']+0)===1)  {
            result=result || ((elem['tereg']+0)===1)
         }
         else {
           result=result || ((elem['tenereg']+0)===1)
         }
      }

      if ((this['tn']+0)===1)
      {
          if ((this['tereg']+0)===1)  {
             result=result || (((elem['tereg']+0)===1) && ((elem['tn']+0)===1))
          }
          else {
            result=result || (((elem['tenereg']+0)===1) && ((elem['tn']+0)===1))
          }
       }


     for (var j = 9; j < 19; j++) {

       if ((this[this.vd[j]]+0)===1) {
          if (((elem[this.vd[j]]+0)===1))
          {
             result=result && ((elem[this.vd[j]]+0)===1)
          }
          else {
            result=false;
            break;
          }
       }



     }



        return (result )
      },

      calcreports(massiv)
      {
        let sum=0

        massiv.forEach((item) => {

          for (var i = 1; i < 13; i++) {
            sum=sum+((item['MON'+i]!==null)+0)
          }
          sum=sum+((item['specdata']!==null)+0)

        });

        return sum
      },













      Isnoproblem(str) {
        try {
          str.trim();
        } catch (e) {
          return true;
        }
        return false;
      },

      log(dd) {
        window.console.log(dd)
      },


      getid() {

        var self = this;
        axios.post(
            'https://calendar.btr-online.ru/Getcalendar',
            JSON.stringify({
                enter: "123",
            })
          )
          .then(function(response) {
            if (
              response.data != "ERROR") {
              self.calendar = (response.data);

            } else {
              window.alert("Код не найден!");
            }
          })
          .catch(function(error) {
            window.console.log(error);
          });

      },
      getfirms() {

        var self = this;
        axios.post(
            'https://calendar.btr-online.ru/Getfirms',
            JSON.stringify({
                enter: "123",
            })
          )
          .then(function(response) {
            if (
              response.data != "ERROR") {
              self.firms = (response.data);

            } else {
              window.alert("Код не найден!");
            }
          })
          .catch(function(error) {
            window.console.log(error);
          });

      },




      styleDoc(i, img) {

        return "backgroundImage: url(" + img + "); width: " + 680 * this.zoom[i] / 100 + "px; height: " + 963 * this.zoom[i] / 100 + "px; transform: rotate(" + this.rot[i] + "deg);"
      },
      setBorderWidth() {
        let i = this.$refs.drawer.$el.querySelector(
          ".v-navigation-drawer__border"
        );
        i.style.width = this.navigation.borderSize + "px";
        i.style.cursor = "ew-resize";
      },
      setEvents() {
        const minSize = this.navigation.borderSize;
        const el = this.$refs.drawer.$el;
        const drawerBorder = el.querySelector(".v-navigation-drawer__border");
        const vm = this;
        const direction = el.classList.contains("v-navigation-drawer--right") ?
          "right" :
          "left";

        function resize(e) {
          document.body.style.cursor = "ew-resize";
          let f = direction === "right" ?
            document.body.scrollWidth - e.clientX :
            e.clientX;
          el.style.width = f + "px";
        }

        drawerBorder.addEventListener(
          "mousedown",
          function(e) {
            if (e.offsetX < minSize) {
              ///m_pos = e.x;
              el.style.transition = 'initial';
              document.addEventListener("mousemove", resize, false);
            }
          },
          false
        );

        document.addEventListener(
          "mouseup",
          function() {
            el.style.transition = '';
            vm.navigation.width = el.style.width;
            document.body.style.cursor = "";
            document.removeEventListener("mousemove", resize, false);
          },
          false
        );
      },



    },

    mounted() {


      this.god_rabot_filter=String((new Date()).getFullYear());









      this.getid();
      this.getfirms();


      if (!this.$cookies.isKey('te'))
      {



                    this.vd.forEach((item) => {
                        this.$cookies.set(item, this[item],31556926);
                    });

      }
      else {

        this.vd.forEach((item) => {

            this[item]=this.convertToBoolean(this.$cookies.get(item));
        });

      }










    },

  };
</script>
