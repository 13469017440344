import { render, staticRenderFns } from "./App.vue?vue&type=template&id=2da350db&"
import script from "./App.vue?vue&type=script&lang=js&"
export * from "./App.vue?vue&type=script&lang=js&"
import style0 from "./App.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VChipGroup } from 'vuetify/lib/components/VChipGroup';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VApp,VAppBar,VBtn,VCard,VCardText,VChip,VChipGroup,VCombobox,VDialog,VDivider,VExpansionPanel,VExpansionPanelContent,VExpansionPanelHeader,VExpansionPanels,VIcon,VMain,VProgressCircular,VSimpleTable,VSpacer,VSwitch,VTab,VTabItem,VTabs,VTabsItems,VTextField,VToolbarTitle})
